import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-listing-details',
  templateUrl: './listing-details.component.html'
})
export class ListingDetailsComponent implements OnInit {
  @Input() listDetails!: any;
  @Input() offer!: any ;
  @Input() isTransaction: boolean = false;
  @Input() isEckardTeam: boolean = false;

  ngOnInit(): void {
    console.log('');
  }
}
