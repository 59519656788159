import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/components/services/login.service';

@Component({
  selector: 'app-seller-transactions',
  templateUrl: './seller-transactions.component.html'
})
export class SellerTransactionsComponent implements OnInit {
  listFilterBy!: string
  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    if (this.loginService?.user?.status != "active" || this.loginService?.user?.role?.name == 'Eckard') {
      this.router.navigate(['/market-place']);
      return

    }
    let routePath = this.router.url.split('/')[1]
    switch (routePath) {
      case 'seller-pending-transactions':
        this.listFilterBy = 'sellerPendingTransactions'
        break
      case 'seller-closed-transactions':
        this.listFilterBy = 'sellerClosedTransactions'
        break
      default:
        return
    }

  }
  transactionStatus = 'Sell'

}
