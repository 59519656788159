<div class="container card mt-4 pt-4 h-100">

  <app-breadcrumb-container
    [containerClass]="'breadcrumb-container mb-3'"
    [breadcrumbItems]="[
      { label: 'Home', route: '/home' },
      { label: 'MarketPlace', route: '/market-place' },
      { label: 'Transactions Methods', route: '' }
    ]"
  ></app-breadcrumb-container>

  <div class="d-flex gap-5 mt-4 mb-3 align-items-center">
    <div>
      <input
        type="search"
        placeholder="Search"
        id="searchParam"
        name="searchParam"
        class="form-control"
        [(ngModel)]="searchParam"
        (ngModelChange)="handleChange()"
      />
    </div>
  </div>
  <div class="table-responsive text-nowrap">
    <table class="table" matSort (matSortChange)="onSortChange($event)">
      <thead>
        <tr>
          <th
            *ngFor="let item of transactionsMethodsColumns; index as i"
            class="text-secondary"
            mat-sort-header="{{ item }}"
          >
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody class="my-listing-table-body text-secondary" id="accordion">
        <ng-container
          *ngFor="
            let transaction of transactionsMethodsData
              | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index
          "
        >
          <tr>
            <td>{{ transaction?.account?.accountName }}</td>

            <td id="heading{{ i }}">
              <span
                data-toggle="collapse"
                [attr.data-target]="'#collapse' + i"
                aria-expanded="false"
                [attr.aria-controls]="'collapse' + i"
                class="collapsed pe-auto transactions-actions"
                title="Methods details"
              >
                {{ transaction?.json_fields?.type }}</span
              >
            </td>
          </tr>
          <tr
            id="collapse{{ i }}"
            class="collapse fade"
            [attr.aria-labelledby]="'heading' + i"
            data-parent="#accordion"
          >
            <td [attr.colspan]="2">
              <div class="card-body border shadow-lg p-4 mb-5 bg-white rounded">
                <app-eckard-transactions-methods-details
                  (handleSubmit)="handleUpdate()"
                  [index]="i"
                  [transaction]="transaction"
                >
                </app-eckard-transactions-methods-details>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div
    class="d-flex justify-content-center"
    *ngIf="transactionsMethodsData && transactionsMethodsData!.length  > 0"
  >
    <pagination-controls
      previousLabel="Prev"
      nextLabel="Next"
      (pageChange)="onTableDataChange($event)"
    >
    </pagination-controls>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
