import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb-container.module';
import { EckardStaffPlaceModule } from '../pages/eckard-staff-place/eckard-staff-place.module';
import { MarketPlaceComponent } from './market-place.component';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
      path: '',
      component: MarketPlaceComponent
    }
  ];

@NgModule({
    declarations: [
        MarketPlaceComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        NgxSpinnerModule,
        EckardStaffPlaceModule,
        BreadcrumbModule,
        RouterModule.forChild(routes)

    ],
    providers: [],
    exports: [MarketPlaceComponent]
})
export class MarketPlaceModule { }
