import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactListing } from 'src/components/model/my-listings';
import { LoginService } from 'src/components/services/login.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { MatSort } from '@angular/material/sort';
import { OfferConfirmMessages } from 'src/components/model/login';
import { environment } from 'src/environments/environment';
import { CustomDatePipe } from 'src/common/custom-date.pipe';


@Component({
  selector: 'app-my-listing',
  templateUrl: './my-listing.component.html',
  styleUrls: ['./my-listing.component.scss']
})
export class MyListingComponent implements OnInit {
  listStatus: string = 'Active';

  showAllListing: boolean = false

  listingsColumns: Array<String> = [
    "Listing Name",
    "Auction Deadline",
    "Account/Project",
    "Listed NMA",
    "Minimum Ask",
    "Highest Offer",
    "# Offers"
  ]

  listingFilterOptions = [
    {
      value: "Active",
      label: "My Active Listings"
    },
    {
      value: "Draft",
      label: "My Drafted Listings"
    },

  ]
  myListings!: ContactListing[];
  copyListings!: ContactListing[];
  userId!: number;

  page: number = 1;
  count: number = 0;
  tableSize: number = 50;
  tableSizes: number[] = [3, 6, 9, 12];
  isShowOffers: boolean = false
  offerConfirmMessages!: OfferConfirmMessages[]

  @ViewChild(MatSort) sort!: MatSort;

  constructor(private myListingsService: MyListingsService, private router: Router,
    private loginService: LoginService, private spinner: NgxSpinnerService, private myOffersService: MyOffersService,private customDatePipe:CustomDatePipe) {
    this.handleResetNewList()
  }

  ngOnInit(): void {
    this.userId = this.loginService.user.id
    if (this.loginService?.user?.status != "active" || this.loginService?.user?.role?.name == 'Eckard') {
      this.router.navigate(['/market-place']);
      return

    }
    this.handleOfferDealMessages()
    this.getAllMyListings()
  }

  handleFilterList() {
    this.page = 1;
    this.count = 0;
    this.tableSize = 50;
    this.getAllMyListings()

  }

  handleToggel() {
    this.showAllListing = !this.showAllListing;
    this.isShowOffers = false
    this.getAllMyListings()

  }

  handleNewList() {
    const defaultTimeItem = this.offerConfirmMessages?.find((item: any) => item.key == environment.DEFAULT_AUCTION_DURATION_KEY);
    const defaultTime = defaultTimeItem ? defaultTimeItem.value1 && parseInt(defaultTimeItem.value1) : null;
    const defaultDuration: number | null = defaultTime ? defaultTime * 60 * 60 * 1000 : null;
    const listingStartDate = new Date(this.myListingsService.newListing.listingStart);
    const fourWeeksFromNow = new Date(listingStartDate.getTime() + Number(defaultDuration));
    const formattedDate = this.customDatePipe.transform(fourWeeksFromNow, 'yyyy-MM-ddTHH:mm');
    this.myListingsService.newListing.auctionEnd = formattedDate;
    this.showAllListing = !this.showAllListing;
    this.isShowOffers = false;
  }

  handleResetNewList() {
    this.isShowOffers = false
    this.myListingsService.handleResetSetNewList()
    this.showAllListing = false

  }

  handleEdit(value: any) {
    this.spinner.show();
    this.myListingsService.getMyList(value.listingId).subscribe(
      (response) => {
        this.myListingsService.isListEdit = true;
        let editList = {
          listing_type: response?.listing_type.id,
          status: response.status,
          listingName: response.listingName,
          listingStart: response.listingStart,
          auction_type: response.auction_type,
          auctionEnd: response.auctionEnd,
          comments: response.comments,
          account: response.account.id,
          project: response.project,
          nma: response.nma,
          minimumAsk: response.minimumAsk,
          buyNowPrice: response.buyNowPrice,
          constraints: response.constraints.map((x: any) => parseInt(x.id)),
          listConstraints: response.constraints,
          offer: response.offer,
          id: response.id,
          directSaleToken: response.directSaleToken,
          is_cancel_allowed: response?.is_cancel_allowed
        }
        if (response.status.status == 'Active') {
          this.myListingsService.isListDraft = false;
        }
        this.myListingsService.newListing = editList
        this.showAllListing = !this.showAllListing;
        this.spinner.hide();

      },
      (error: any) => {
        console.log(error)
        this.spinner.hide();
      },
      () => console.log("Done getting list details"));

  }

  handleOffers(value: any) {
    this.isShowOffers = !this.isShowOffers
    this.handleEdit(value)
    this.myListingsService.showOffers = true;
  }

  getAllMyListings() {
    this.spinner.show();
    let offset = (this.page - 1) * this.tableSize
    this.myListingsService.getAllMyListings(this.loginService.user.id, this.tableSize, offset, this.listStatus).subscribe(
      (response: any) => {
        this.myListings = response?.data?.map((list: any) => ({
          ...list,
          auctionEnd: this.customDatePipe.getFormattedDate(list.auctionEnd)
        }))
        this.copyListings = this.myListings
        this.page = response?.metadata?.page
        this.count = response?.metadata?.total_results
        this.tableSize = response?.metadata?.limit
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error)
        this.spinner.hide();
      },
      () => console.log("Done getting list details"));
  }


  onTableDataChange(event: any) {
    this.page = event;
    this.getAllMyListings();
  }


  handleListingLength() {
    if (this.myListings) {
      return this.myListings.length
    }
    return 0
  }

  handleOfferDealMessages() {
    this.myOffersService.handleOfferDealMessages().subscribe(
      (response: any) => {
        this.offerConfirmMessages = response

      },
      (error: any) => {
        console.error("Error getting key vlaue  : ", error);
      },
      () => console.log("Done getting key vlaue .")
    )
  }

  onSortChange(event: any): void {
    let sortProperty = '';
    switch (event.active) {
      case 'Listing Name':
        sortProperty = 'listingName'
        break;
      case 'Auction Deadline':
        sortProperty = 'auctionEnd'
        break;
      case 'Account/Project':
        sortProperty = 'Account/Project'
        break;
      case 'Listed NMA':
        sortProperty = 'listedNMA'
        break;
      case 'Minimum Ask':
        sortProperty = 'minimumAsk'
        break;
      case 'Highest Offer':
        sortProperty = 'highestBid'
        break;
      case '# Offers':
        sortProperty = '# Bids'
        break;
      default:
        return
    }
    const sortOrder = event.direction === 'asc' ? 1 : -1;

    this.myListings = this.myListings.sort((a: any, b: any) => {
      const aValue = a[sortProperty];
      const bValue = b[sortProperty];

      if (aValue < bValue) {
        return -1 * sortOrder;
      } else if (aValue > bValue) {
        return 1 * sortOrder;
      } else {
        return 0;
      }
    });
  }

}
