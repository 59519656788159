import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MyOffersComponent } from './my-offers.component';
import { CashFlowCalculatorModule } from 'src/components/pages/cash-flow-calculator/cash-flow-calculator.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        MyOffersComponent,
    ],
    imports: [
        NgMultiSelectDropDownModule.forRoot(),
        RouterModule,
        FormsModule,
        CommonModule,
        NgxPaginationModule,
        CurrencyMaskModule,
        NgxSpinnerModule,
        CashFlowCalculatorModule

    ],
    providers: [],
    exports: [MyOffersComponent]
})
export class MyOffersDetailsModule { }
