<div class="mt-3">
    <form class="mb-3">

        <div class="d-flex  justify-content-start">
            <ng-container *ngIf="!isListEdit ; else elseBlock">
                <h4 class="ml-2"> New Listing </h4>
            </ng-container>
            <ng-template #elseBlock>
                <h4 class="ml-2">{{ listingId }} </h4>
            </ng-template>

            <div class="d-flex text-center  align-items-center" *ngIf="false">
                <ng-container *ngIf="listingTypeOptions">

                    <div class="form-check mx-4" *ngFor="let item of listingTypeOptions;">
                        <input class="form-check-input" (click)="handleProjectType(item.id)" type="radio"
                            name="listing_type" [id]="item.id" [checked]="createNewListing.listing_type == item.id"
                            [disabled]="!isListDraft">
                        <label class="form-check-label" [for]="item.id">
                            {{item.listingType}}
                        </label>
                    </div>
                </ng-container>
            </div>

        </div>
        <ng-container *ngIf="createNewListing.listing_type != 2; else elseBlockList">
            <div class="row p-4">
                <div class="col-md-6">
                    <label for="listingName" class="form-label">Listing Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="createNewListing.listingName" id="listingName"
                        name="listingName" required #listingName="ngModel"
                        [ngClass]="{'border border-danger':listingName.invalid && (listingName.dirty || listingName.touched)}"
                        [disabled]="showOffers">
                    <span
                        *ngIf="listingName.invalid && (listingName.dirty || listingName.touched ) &&  listingName.errors"
                        class="text-danger rounded px-4 error-message">
                        Please enter valid listing name
                    </span>
                </div>

                <div class="col-md-6">
                    <label for="listingStart" class="form-label">Listing Start <span
                            class="text-danger">*</span></label>
                    <input type="datetime-local" class="form-control" [(ngModel)]="createNewListing.listingStart"
                        (ngModelChange)="handleTimeDuration()" name="listingStart" id="listingStart" required
                        #listingStart="ngModel"
                        [ngClass]="{'border border-danger':listingStart.invalid && (listingStart.dirty || listingStart.touched)}"
                        [disabled]="!isListDraft">
                    <span
                        *ngIf="listingStart.invalid && (listingStart.dirty || listingStart.touched ) &&  listingStart.errors"
                        class="text-danger rounded px-4 error-message">
                        Please enter valid listing Start date
                    </span>
                </div>
            </div>
            <div class="row p-4">
                <div class="col-md-6">
                    <label for="auctionType" class="form-label">Auction Type <span class="text-danger">*</span></label>
                    <select id="auctionType" name="auctionType" class="form-select"
                        [compareWith]="auctionTypeComparator" [(ngModel)]="createNewListing.auction_type"
                        #auctionType="ngModel"
                        [ngClass]="{'border border-danger':auctionType.invalid && (auctionType.dirty || auctionType.touched)}"
                        required [disabled]="!isListDraft">
                        <option *ngFor="let item of auctionTypeOptions" [ngValue]="item">
                            {{item.auctionLabel}}
                        </option>
                    </select>
                    <span
                        *ngIf="auctionType.invalid && (auctionType.dirty || auctionType.touched ) &&  auctionType.errors"
                        class="text-danger rounded px-4 error-message">
                        Please select valid Auction Type
                    </span>


                </div>
                <div class="col-md-6">
                    <label for="auctionEnd" class="form-label">Auction Ends At <span
                            class="text-danger">*</span></label>
                    <input type="datetime-local" class="form-control" name="auctionEnd" id="auctionEnd"
                        [(ngModel)]="createNewListing.auctionEnd" min="{{minAuctionDuration}}"
                        max="{{maxAuctionDuration}}" required #auctionEnd="ngModel"
                        (ngModelChange)="handleTimeDuration()"
                        [ngClass]="{'border border-danger':auctionEnd.invalid && (auctionEnd.dirty || auctionEnd.touched)}"
                        [disabled]="!isListDraft">
                    <span *ngIf="auctionEnd.invalid && (auctionEnd.dirty || auctionEnd.touched ) &&  auctionEnd.errors"
                        class="text-danger rounded px-4 error-message">
                        Please enter valid auction End date
                    </span>
                </div>
            </div>

            <div class="row p-4">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active " id="GeneralData-tab" data-bs-toggle="tab"
                            data-bs-target="#GeneralData" type="button" role="tab" aria-controls="GeneralData"
                            aria-selected="true">General Data</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link " id="listingDetails-tab" data-bs-toggle="tab"
                            data-bs-target="#listingDetails" type="button" role="tab" aria-controls="listingDetails"
                            aria-selected="false">Listing
                            Details</button>
                    </li>

                    <li class="nav-item" role="presentation" *ngIf="false">
                        <button class="nav-link " id="addTracts-tab" data-bs-toggle="tab" data-bs-target="#addTracts"
                            type="button" role="tab" aria-controls="addTracts" aria-selected="false">Add Tracts
                        </button>
                    </li>

                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active " id="GeneralData" role="tabpanel"
                        aria-labelledby="GeneralData-tab">

                        <app-general-data-tab [auctionTypeOptions]="auctionTypeOptions"
                            [constraintOptions]="constraintOptions" [createNewListing]="createNewListing"
                            [isListDraft]="isListDraft">
                        </app-general-data-tab>
                    </div>
                    <div class="tab-pane fade" id="listingDetails" role="tabpanel" aria-labelledby="listingDetails-tab">
                        <app-listing-details-tab [createNewListing]="createNewListing"
                            [accountsOptions]="accountsOptions" [tracts]="tracts" [isListDraft]="isListDraft"
                            (isValidNMA)="handleValidNMA($event)" [isListEdit]="isListEdit"
                            [offerConfirmMessages]="offerConfirmMessages">
                        </app-listing-details-tab>
                    </div>
                    <div class="tab-pane fade " id="addTracts" role="tabpanel" aria-labelledby="addTracts-tab"
                        *ngIf="false">

                        <app-add-tracts-tab [createNewListing]="createNewListing" [isListDraft]="isListDraft">
                        </app-add-tracts-tab>
                    </div>

                </div>

            </div>
            <ng-container *ngIf="showOffers">

                <div class="row p-4">
                    <all-offers-tab [ListingDetails]="createNewListing" [statusOptions]="statusOptions"
                        (handleSubmit)="handleGoBack()"></all-offers-tab>
                </div>
            </ng-container>


            <div class="d-flex justify-content-between p-4 col-lg-10 col-sm-12" *ngIf="!showOffers">
                <div class="d-flex" *ngIf="isValidDrafts(createNewListing)">
                    <ng-container *ngFor="let item of statusOptions; index as i" >
                        <ng-container
                            *ngIf="item.status == 'Draft' || (item.status == 'Active' && isValid(createNewListing) && isValidNma) || (item.status == 'Cancelled' && isListEdit && handleGetOffers(createNewListing.offer) )">
                            <input type="submit" class="btn text-white mx-2 text-uppercase"
                                [value]="item.status == 'Cancelled'  ? (!createNewListing.is_cancel_allowed ? 'cancel not allowed':'CANCEL LISTING') : item.status == 'Draft' ? 'SAVE DRAFT': (!isListDraft ? 'UPDATE LISTING':'PUBLISH LISTING') "
                                [ngClass]="{'btn-success ': item.status == 'Draft' , 'btn-warning': item.status == 'Active','btn-danger': item.status == 'Cancelled' && createNewListing.is_cancel_allowed, 'border border-danger bg-secondary': item.status == 'Cancelled' && !createNewListing.is_cancel_allowed, 'd-none':(!isListDraft && item.status == 'Draft')}"
                                (click)=" handleMessage($event)" data-bs-toggle="modal"
                                [attr.data-bs-target]="'#exampleModal'+i" [disabled]="item.status == 'Cancelled' && !createNewListing.is_cancel_allowed" />
                        </ng-container>
                        <ng-container>
                            <div class="modal fade text-wrap" id="exampleModal{{i}}" data-bs-backdrop="static"
                                data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel"
                                                [innerHTML]="sanitizeHTML(listMessage.value1)">
                                            </h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body" [innerHTML]="sanitizeHTML(listMessage.value2)">
                                        </div>
                                        <div class="modal-footer">
                                            <div class="mr-auto ">

                                                <button type="button" class="btn btn-warning text-white mr-2 fw-bold"
                                                    data-bs-dismiss="modal" (click)="handleStatus(item)">YES</button>
                                                <button type="button" class="btn btn-danger ml-2 fw-bold"
                                                    data-bs-dismiss="modal">NO</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                </div>
                <button type="button" class="btn btn-secondary ml-auto" (click)="handleGoBack()">Go Back</button>

            </div>

        </ng-container>
        <ng-template #elseBlockList>
            <div
                class=" h-100 d-flex mx-auto align-items-center justify-content-center max-w-7xl text-center p-5 mt-5 mb-5">

                Still Work in Progress ....

            </div>
        </ng-template>

    </form>


</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
</ngx-spinner>