<div class="row mt-4">
  <div class="table-responsive text-nowrap">
    <table
      class="table table-borderless"
      matSort
      (matSortChange)="onSortChange($event)"
    >
      <thead>
        <tr>
          <ng-container *ngFor="let item of offersTableHead; index as i">
            <th
              class="text-secondary"
              *ngIf="item !== 'Action' && item != 'Constraints'"
              mat-sort-header="{{ item }}"
            >
              {{ item }}
            </th>
            <th
              class="text-secondary"
              *ngIf="item === 'Action' || item == 'Constraints'"
            >
              {{ item }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let offer of offers
              | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index
          "
        >
          <td class="text-secondary">{{ offer?.contact?.mpName }}</td>
          <td class="text-secondary">
            {{ offer?.offerAmount | currency : "" : "" : ".2-2" }}
          </td>
          <td>
            <ng-container
              *ngIf="
                offer?.status?.status == 'Active' &&
                ListingDetails?.status?.statusLabel != 'Accepted'
              "
            >
              <span
                for="accept"
                data-bs-target="#staticBackdrop"
                data-bs-toggle="modal"
                (click)="handleSellMessage(offer)"
                class="form-label d-inline-block border-bottom border-primary text-primary pe-auto border-primary"
              >
                accept
              </span>
            </ng-container>
            <ng-container
              *ngIf="
                offer?.status?.status == 'Accepted' &&
                ListingDetails?.status?.statusLabel == 'Accepted'
              "
            >
              <span class="text-secondary">Accepted</span>
            </ng-container>
            <ng-container
              *ngIf="
                offer?.status?.status == 'Active' &&
                ListingDetails?.status?.statusLabel == 'Accepted'
              "
            >
              <span class="text-secondary">No Action</span>
            </ng-container>
          </td>
          <td class="text-secondary">
            <p
              *ngFor="let constraint of offer.constraints"
              [ngClass]="{ 'text-danger': constraint?.block }"
            >
              <span *ngIf="constraint.missing" class="text-danger"
                >Missing
              </span>
              {{ constraint.buyLabel }}
            </p>
          </td>
          <td class="text-secondary">{{ offer.comments }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    <pagination-controls
      previousLabel="Prev"
      nextLabel="Next"
      (pageChange)="onTableDataChange($event)"
    >
    </pagination-controls>
  </div>
  <div
    class="modal fade text-wrap"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
          ></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-body"
          [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"
        ></div>
        <div class="modal-footer">
          <div class="mr-auto">
            <button
              type="button"
              class="btn btn-warning text-white mr-2 fw-bold"
              data-bs-dismiss="modal"
              (click)="handleSubmitOffer()"
            >
              ACCEPT
            </button>
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-danger ml-2 fw-bold"
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
