<div [class]="containerClass">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb breadcrumb-link">
      <li
        *ngFor="let item of breadcrumbItems; let last = last"
        class="breadcrumb-item"
      >
        <a *ngIf="!last; else spanLink" [routerLink]="item?.route">{{
          item.label
        }}</a>
        <ng-template #spanLink>
          <a
            [routerLink]="item.route ? item.route : null "
            [class.pe-auto]="item.route === '/my-listing'"
            (click)="item.route === '/my-listing' ? handleClick() : null"
            >{{ item.label }}</a
          >
        </ng-template>
      </li>
    </ol>
    <ng-container *ngIf="isActiveUser">
      <div
        class="d-flex justify-content-between text-center align-items-center col-lg-3 col-md-4 px-0"
      >
        <div class="text-danger">
          <strong>inactive</strong>
        </div>
      </div>
    </ng-container>
  </nav>
</div>
