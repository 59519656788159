import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root',
})

export class ActivityService {

  private lastActivity!: Date;
  
  constructor(private router: Router) {
    this.resetTimer();
    this.initActivityListener();
  }

  resetTimer() {
    this.lastActivity = new Date();
  }

  initActivityListener() {
    document.addEventListener('mousemove', () => this.resetTimer());
    document.addEventListener('keypress', () => this.resetTimer());
  }

  startInactivityTimer() {
    setInterval(() => {
      const currentTime = new Date();
      const elapsedTime = currentTime.getTime() - this.lastActivity.getTime();
      const inactivityThreshold = 30 * 60 * 1000; // 30 minutes
      if (elapsedTime > inactivityThreshold) {
        this.logout();
      }
    }, 60000); // Check every minute
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }

}
