import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private loginService: LoginService, private router: Router) {

  }
  canActivate() {
    let isAccessToken = localStorage.getItem('accessToken');
    let isRefreshToken = localStorage.getItem('refreshToken');
    if (!isAccessToken || !isRefreshToken) {
      localStorage.clear();
      this.router.navigate(['']);
      return false
    }
    if (this.loginService?.isAuthanticated) {
      return true;
    }

    this.router.navigate(['']);
    return false;

  }

}
