import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/components/services/login.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { MatSort } from '@angular/material/sort';
import { FundsMethods } from 'src/components/model/my-listings';

@Component({
  selector: 'app-eckard-transactions-methods',
  templateUrl: './eckard-transactions-methods.component.html',
  styleUrls: ['./eckard-transactions-methods.component.css'],
})
export class EckardTransactionsMethodsComponent implements OnInit {
  transactionsMethodsColumns = ['Account', 'Type'];
  transactionsMethodData = [];
  page: number = 1;
  count: number = 0;
  tableSize: number = 50;
  tableSizes: number[] = [3, 6, 9, 12];
  searchParam = '';
  transactionsMethodsData: FundsMethods[] = [];
  copyTransactionsData: FundsMethods[] = [];
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private spinner: NgxSpinnerService,
    private myListingsService: MyListingsService,
    private loginService: LoginService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (
      this.loginService?.user?.status != 'active' ||
      this.loginService?.user?.role?.name != 'Eckard'
    ) {
      this.router.navigate(['/market-place']);
      return;
    }
    this.handleGetEckardTransactionsMethods();
  }

  handleGetEckardTransactionsMethods() {
    this.spinner.show();

    this.myListingsService.handleGetEckardTransactionsMethods().subscribe(
      (response: any) => {
        this.spinner.hide();

        this.transactionsMethodsData = response;
        this.copyTransactionsData = response;
        this.handleChange();
      },
      (error: any) => {
        this.spinner.hide();
        console.log('Error getting  Pending Asset Transfer', error);
      },
      () => console.log('Done getting  Pending Asset Transfer ')
    );
  }
  onTableDataChange(event: any) {
    this.page = event;
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  handleChange() {
    if (!this.searchParam) {
      this.transactionsMethodsData = this.copyTransactionsData;
      return;
    }

    const filteredData = this.copyTransactionsData?.filter((item: any) => {
      const searchParamLower = this.searchParam.toLowerCase(); // convert searchParam to lowercase
      const itemFTMAccountName = item?.account?.accountName?.toLowerCase();
      return itemFTMAccountName.includes(searchParamLower);
    });

    this.transactionsMethodsData = filteredData;
  }

  handleUpdate() {
    this.handleGetEckardTransactionsMethods();
  }

  onSortChange(event: any): void {
    let sortProperty = '';
    switch (event.active) {
      case 'Account':
        sortProperty = 'account.accountName';
        break;
      case 'Type':
        sortProperty = 'json_fields.type';
        break;

      default:
        return;
    }
    const sortOrder = event.direction === 'asc' ? 1 : -1;

    this.transactionsMethodsData = this.transactionsMethodsData.sort(
      (a: any, b: any) => {
        const aValue = this.getPropertyValue(a, sortProperty);
        const bValue = this.getPropertyValue(b, sortProperty);

        if (aValue < bValue) {
          return -1 * sortOrder;
        } else if (aValue > bValue) {
          return 1 * sortOrder;
        } else {
          return 0;
        }
      }
    );
  }

  getPropertyValue(object: any, propertyPath: string): any {
    const props = propertyPath.split('.');
    let value = object;
    for (const prop of props) {
      value = value[prop];
    }
    return value;
  }
}
