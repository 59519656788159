import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { loginModule } from 'src/components/auth/login/login.module';
import { MarketPlaceModule } from 'src/components/market-place/market-place.module';
import { BuyerModule } from 'src/components/pages/buyer/buyer.module';
import { InformationsModule } from 'src/components/pages/informations/informations.module';
import { SellerModule } from 'src/components/pages/seller/seller.module';
import { AddNewListingService } from 'src/components/services/add-new-listing.service';
import { NavbarModule } from 'src/components/navbar/navbar.module';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ProfileModule } from 'src/components/pages/profile/profile.module';
import { TransactionsModule } from 'src/components/pages/transactions/transaction.module';
import { EckardTransactionsModule } from 'src/components/pages/eckard-staff-place/eckard-transactions/eckard-transactions.module';
import { EckardTransactionsMethodsModule } from 'src/components/pages/eckard-staff-place/eckard-transactions-methods/eckard-transactions-methods.module';
import { SignUpModule } from 'src/components/auth/sign-up/sign-up.module';
import { NotificationsModule } from 'src/components/pages/notifications/notifications.module';
import { CashFlowCalculatorModule } from 'src/components/pages/cash-flow-calculator/cash-flow-calculator.module';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { CurrencyPipe } from '@angular/common';
import { LoginService } from 'src/components/services/login.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { BreadcrumbModule } from 'src/components/breadcrumb/breadcrumb-container.module';
import { CommonService } from 'src/components/services/common.service';
import { CustomDatePipe } from 'src/common/custom-date.pipe';
import { DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AllActiveListingsModule } from 'src/components/pages/buyer/my-bids/all-active-listing/all-active-listings.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NavbarModule,
    FormsModule,
    HttpClientModule,
    loginModule,
    MarketPlaceModule,
    BuyerModule,
    SellerModule,
    InformationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      tapToDismiss: true,
    }), // ToastrModule added
    NgxPaginationModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    NgxSpinnerModule,
    ProfileModule,
    TransactionsModule,
    EckardTransactionsModule,
    EckardTransactionsMethodsModule,
    SignUpModule,
    NotificationsModule,
    CashFlowCalculatorModule,
    BreadcrumbModule,
    AllActiveListingsModule
  ],
  providers: [
    AddNewListingService,
    MyListingsService,
    MyOffersService,
    ToastrService,
    LoginService,
    CurrencyPipe,
    NgxSpinnerService,
    CommonService,
    CustomDatePipe,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
