<form class=" auth-container-form capital-form" #funds="ngForm">
    <h5 class="auth-container-title">
        Add Capital Amount
    </h5>

    <div class="mt-3 px-4 position-relative">

        <label for="Seller" class="form-label fw-bold">Available Capital: </label>
        <span class=" px-2 text-muted listing-details-content">

            {{capitalAmount.availableCapital |
            currency:'CAD':'symbol-narrow':'.0-0'}}
        </span>
    </div>

    <div class="mt-3 px-4 position-relative">
        <input oninput="validity.valid||(value='');" class="form-control py-2 px-5" [(ngModel)]="capitalAmount.addFunds"
            name="addFunds" id="addFunds" placeholder="Add Funds" #fund="ngModel" required currencyMask
            [options]="{ prefix: '$', thousands: ',', precision: 0, align:'left', allowNegative: false}">

    </div>


    <div class="px-4 mt-3">
        <input type="submit" class="btn btn-warning btn-lg form-control form-button" value="Add Funds"
            (click)="handleMessage()" [disabled]="funds.form.invalid || capitalAmount.addFunds == 0" />
    </div>
</form>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
</ngx-spinner>


<div class="modal fade text-wrap" id="exampleModal1" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true" [ngClass]="{ 'show': isShow == true }"
    [style.display]="isShow == true ? 'block' : 'none'">
    <div class="modal-dialog modal-dialog-centered  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel" [innerHTML]="sanitizeHTML(offerDisclaimer.value1)">
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="isShow = false"></button>
            </div>
            <div class="modal-body" [innerHTML]="sanitizeHTML(offerDisclaimer.value2)">
            </div>
            <div class="modal-footer">
                <div class="mr-auto">
                    <button type="button" class="btn btn-warning text-white mr-2 fw-bold" data-dismiss="modal"
                        (click)="handleAddCapitalAmount()">
                        YES
                    </button>
                    <button type="button" class="btn btn-danger ml-2 fw-bold" data-bs-dismiss="modal"
                        (click)="isShow = false">
                        NO
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>