import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { UserAgreementComponent } from './user-agreement.component';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
      path: '',
      component: UserAgreementComponent
    }
  ];

@NgModule({
    declarations: [
        UserAgreementComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FormsModule,
        CommonModule,

    ],
    providers: [],
    exports: [UserAgreementComponent]
})
export class UserAgreementModule { }
