import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotificationsModule } from '../../notifications/notifications.module';
import { SellerNotificationsComponent } from './seller-notifications.component';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
      path: '',
      component: SellerNotificationsComponent
    }
  ];

@NgModule({
    declarations: [
        SellerNotificationsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FormsModule,
        NotificationsModule
    ],
    providers: [],
    exports: [SellerNotificationsComponent]
})
export class SellerNotificationsModule { }
