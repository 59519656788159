<div class="container card mt-4 pt-4 h-100">
  <app-breadcrumb-container
    [containerClass]="'breadcrumb-container '"
    [breadcrumbItems]="[
      { label: 'Home', route: '/home' },
      { label: 'MarketPlace', route: '/market-place' },
      { label: ['buyerPendingTransactions', 'sellerPendingTransactions'].includes(listFilterBy) ? 'My Pending Transactions' : 'My Closed Transactions' , route: '' }
    ]"

  ></app-breadcrumb-container>

  <div class="d-flex gap-5 mt-4 mb-3 align-items-center">

    <div class="d-inline-block mr-2">
      <h3>Transactions</h3>
    </div>
    <div class="d-inline-block pe-auto list-type-heading ml-2" (click)="toggleListing('Buy')">
      <span [ngClass]="{'list-underline': transactionStatus =='Buy'}">Buying Transactions</span>
    </div>
    <div class="d-inline-block pe-auto  list-type-heading ml-2" (click)="toggleListing('Sell')">
      <span [ngClass]="{'list-underline': transactionStatus =='Sell'}">Selling Transactions</span>
    </div>


  </div>
  <div class="table-responsive text-nowrap">
    <table class="table" matSort (matSortChange)="onSortChange($event)">
      <thead>
        <tr>
          <ng-container *ngFor="let item of transactionColumns; index as i">
            <th class="text-secondary" [ngClass]="{'text-end': i >= 2 && i <= 3, 'user-name': i == 4}"
              *ngIf="item !== 'Action' && item != 'Progress'" mat-sort-header="{{item}}">{{item}}</th>
            <th class="text-secondary" [ngClass]="{'text-end': i >= 2 && i <= 3, 'user-name': i == 4}"
              *ngIf="item === 'Action'  || item == 'Progress'">{{item}}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody class="my-listing-table-body text-secondary" id="accordion">
        <ng-container *ngFor="let transaction of pendingsTransactions | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index">

          <tr>
            <td id="heading{{i}}">
              <span data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="false"
                (click)="clickTransaction(transaction)" [attr.aria-controls]="'collapse'+i"
                class="data-details collapsed pe-auto" title="Transactions details">

                {{transaction?.trnxId | uppercase}}
              </span>
            </td>
            <td>
              {{transaction?.listing?.project?.projectId}}
            </td>
            <td> {{transaction!.nma}}</td>
            <td>{{transaction.trans_amount | currency:'':'':'.2-2'}}</td>
            <td class="pl-5" id="heading{{i}}{{transaction.listing.id}}">
              <ng-container *ngIf="transactionStatus =='Buy' ; else statusElseBlock">
                <span class="pe-auto collapsed transactions-actions" data-toggle="collapse"
                  [attr.data-target]="'#collapse'+i+transaction.listing.id" aria-expanded="false"
                  (click)='handleEmailNotifications(transaction)'>
                  {{transaction.listing.account.mpName}}
                </span>
              </ng-container>
              <ng-template #statusElseBlock>
                <span class="pe-auto collapsed transactions-actions" data-toggle="collapse"
                  [attr.data-target]="'#collapse'+i+transaction.listing.id" aria-expanded="false"
                  (click)='handleEmailNotifications(transaction)'>

                  {{transaction?.offer?.contact?.mpName}}
                </span>
              </ng-template>

            </td>
            <ng-container
              *ngIf="listFilterBy == 'buyerPendingTransactions' || listFilterBy == 'sellerPendingTransactions' ; else transactionDealDateElseBlock">
              <td>
                <ng-container *ngIf="transactionStatus =='Buy'">

                  <ng-container *ngIf="transaction.status.statusLabel == 'PSA Fully Executed' ; else elseBuyerActions">
                    <span class="pe-auto transactions-actions" [attr.data-bs-target]="'#exampleModal'"
                      data-bs-toggle="modal"
                      (click)=" handleAlertTransactionsMessage(transaction,'PSA Fully Executed')">
                      Confirm Funds Transferred</span>
                  </ng-container>

                  <ng-template #elseBuyerActions>
                    N/A
                  </ng-template>

                </ng-container>

                <ng-container *ngIf="transactionStatus =='Sell'">
                  <ng-container
                    *ngIf="transaction.status.statusLabel == 'PSA Fully Executed' || transaction.status.statusLabel == 'Fund Transfer Initiated' ;else  elseSellerBlockAction">
                    <span span class="pe-auto  transactions-actions" [attr.data-bs-target]="'#exampleModal'"
                      data-bs-toggle="modal"
                      (click)="handleAlertTransactionsMessage(transaction,'Fund Transfer Confirmed')">Confirm Funds
                      Received</span>
                  </ng-container>

                  <ng-template #elseSellerBlockAction>
                    N/A
                  </ng-template>

                </ng-container>

              </td>
              <td>{{transaction?.status?.statusLabel}}</td>
              <td>{{transaction.deal_date | date:'MMM d, y'}}</td>
              <td>
                <span class="progress-border p-1 status-E"
                  [ngClass]="{'completed': ['Pending PSA Signatures','PSA Fully Executed','Fund Transfer Initiated','Fund Transfer Confirmed','Asset Transfer Completed','Pending Asset Transfer'].includes(transaction?.status?.statusLabel ?? ' ') }">
                  E</span>
                <span class="progress-border p-1 status-E"
                  [ngClass]="{'completed': ['PSA Fully Executed','Fund Transfer Initiated','Fund Transfer Confirmed','Asset Transfer Completed','Pending Asset Transfer'].includes(transaction.status.statusLabel ?? ' ') }">E</span>
                <span class="progress-border p-1 status-B"
                  [ngClass]="{'completed': ['Fund Transfer Initiated','Fund Transfer Confirmed','Asset Transfer Completed','Pending Asset Transfer'].includes(transaction.status.statusLabel ?? ' ') }">B</span>
                <span class="progress-border p-1 status-S"
                  [ngClass]="{'completed': ['Fund Transfer Confirmed','Asset Transfer Completed','Pending Asset Transfer'].includes(transaction.status.statusLabel ?? ' ') }">S</span>
                <span class="progress-border p-1 status-E"
                  [ngClass]="{'completed': ['Asset Transfer Completed'].includes(transaction.status.statusLabel ?? ' '), 'eckard-assert-pending':['Pending Asset Transfer'].includes(transaction.status.statusLabel ?? ' ') }">E</span>
              </td>
            </ng-container>
            <ng-template #transactionDealDateElseBlock>
              <td>{{transaction.transfer_date | date:'MMM d, y'}} </td>
            </ng-template>

          </tr>
          <tr id="collapse{{i}}" class=" collapse fade" [attr.aria-labelledby]="'heading'+i" data-parent="#accordion">
            <td
              [attr.colspan]="listFilterBy == 'buyerPendingTransactions' || listFilterBy == 'sellerPendingTransactions' ? '9' :'6'">

              <div class="card-body border shadow-lg p-4 mb-5 bg-white rounded">

                <app-listing-details [listDetails]="transaction.listing" [offer]="transaction.listing"
                  [isTransaction]="true">
                </app-listing-details>
                <hr>


                <div *ngIf="transactionStatus === 'Sell'">

                  <div class="mt-4">
                    <h5 class="offer-heading" style="color: navy;">
                      Transfer Method Details
                    </h5>
                  </div>

                  <ng-container *ngIf="accountsMethods && (accountsMethods?.Wire?.length > 0 || accountsMethods?.Check?.length > 0) ; else accountsMethodsElseBlock">

                    <div *ngFor="let k of objectKeys(accountsMethods)" class="mt-4"
                      [ngClass]="{' d-flex gap-5': methodAssocietedAlready}">
  
                      <div [ngClass]="{' pt-2 fw-bold':methodAssocietedAlready}">
                        <ng-container *ngIf="methodAssocietedAlready">
  
                          <input type="checkbox" checked="true" style="margin-right: 10px;"
                            [disabled]="methodAssocietedAlready">
                        </ng-container>
                        {{ k }}
                      </div>
                      <div [ngClass]="{'p-0 ':methodAssocietedAlready , 'transaction-methods': !methodAssocietedAlready}">
                        <table class="table table-borderless">
                          <ng-container *ngFor="let list of accountsMethods[k]; let z = index">
  
                            <thead *ngIf="z==0">
                              <!-- <tr>
                              <th class="text-secondary" *ngFor="let key of objectKeys(list.json_fields)">{{key}}</th>
                            </tr> -->
                              <tr>
                                <th class="text-secondary" *ngFor="let key of objectKeys(list.json_fields)"
                                  [ngClass]="{'w-20':(key=='Eckard Account' || key == 'Account Holder') && !methodAssocietedAlready}">
                                  {{key}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td *ngFor="let key of objectKeys(list.json_fields);index as j">
                                  <ng-container *ngIf="!methodAssocietedAlready">
  
                                    <input type="checkbox" [(ngModel)]="list.current" style="margin-right: 10px;"
                                      *ngIf="j==0" [disabled]="methodAssocietedAlready"
                                      [attr.data-bs-target]="'#exampleModal'+i+list.id" data-bs-toggle="modal"
                                      (click)="handleAlertMessage()">
                                  </ng-container>
                                  <ng-container *ngIf="key === 'Account Number' || key === 'ABA Routing Number'">
                                    <ng-container
                                      *ngIf="showData[i+z] && key === 'Account Number' || showData1[i+z] && key === 'ABA Routing Number'">
                                      {{ list.json_fields[key] }}
                                    </ng-container>
                                    <ng-container
                                      *ngIf="!showData[i+z] && key === 'Account Number' || !showData1[i+z] && key === 'ABA Routing Number'">
                                      ..{{ list.json_fields[key].toString().slice(-4) }}
                                    </ng-container>
                                  </ng-container>
                                  <ng-container *ngIf="key !== 'Account Number' && key !== 'ABA Routing Number'">
                                    <span>
                                      <ng-container *ngIf="key === 'Eckard Account'">
                                        <ng-container *ngIf="handleFTMAccepted(list)">
                                          <img src="assets/eckard-approve.svg" class="mx-1 eckard-ftm-approve"
                                            alt="ftm-approve" />
                                        </ng-container>
                                      </ng-container>
  
                                      {{ list.json_fields[key] }}
                                    </span>
  
                                  </ng-container>
                                  <ng-container *ngIf="key === 'Account Number'">
                                    <i class="fa fa-email pe-auto px-4"
                                      [ngClass]="showData[i+z] ? 'fa-eye-slash' : 'fa-eye'" (click)="toggleData(i+z)"></i>
                                  </ng-container>
                                  <ng-container *ngIf="key === 'ABA Routing Number'">
                                    <i class="fa fa-email pe-auto px-4"
                                      [ngClass]="showData1[i+z] ? 'fa-eye-slash' : 'fa-eye'"
                                      (click)="toggleData1(i+z)"></i>
                                  </ng-container>
                                  <div class="modal fade text-wrap" id="exampleModal{{i}}{{list.id}}" data-bs-backdrop="static"
                                    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                                      <div class="modal-content bg-white">
                                        <div class="modal-header">
                                          <h5 class="modal-title" id="staticBackdropLabel"
                                            [innerHTML]="sanitizeHTML(offerDisclaimer.value1)">
                                          </h5>
                                          <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" (click)="list.current = false"></button>
                                        </div>
                                        <div class="modal-body" [innerHTML]="sanitizeHTML(offerDisclaimer.value2)">
                                        </div>
                                        <div class="modal-footer">
                                          <div class="mr-auto ">
  
                                            <button type="button" class="btn btn-warning text-white mr-2 fw-bold"
                                              data-bs-dismiss="modal" (click)="associateMethod(list)">YES</button>
                                            <button type="button" class="btn btn-danger ml-2 fw-bold"
                                              data-bs-dismiss="modal" (click)="list.current = false">NO</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
  
                                </td>
                              </tr>
  
                            </tbody>
  
                          </ng-container>
                        </table>
                      </div>
                    </div>
                    
                  </ng-container>
                
                  <ng-template #accountsMethodsElseBlock>
                    <div style="padding-top: 10px;font-size: medium;">
                      N/A
                    </div>
                  </ng-template>

                </div>

                <div *ngIf="transactionStatus !== 'Sell'">
                  <div class="mt-4">
                    <h5 class="offer-heading" style="color: navy;">
                      <ng-container>

                        Transfer Method Details
                      </ng-container>

                    </h5>
                  </div>

                  <div *ngIf="transaction?.fund_transfer_method?.length==0">

                    <div style="padding-top: 10px;font-size: medium;">
                      N/A
                    </div>
                  </div>


                  <div *ngFor="let item of transaction.fund_transfer_method; let i = index" class="d-flex gap-5">
                    <div class="pt-2 fw-bold d-flex gap-2" *ngIf="item">
                      <div>{{item.type}}</div>
                      <div *ngIf="handleFTMAccepted(item)">
                        <img src="assets/eckard-approve.svg" class="mx-1 pb-1 eckard-ftm-approve" alt="ftm-approve" />
                      </div>
                    </div>
                    <div class="p-0 w-100">
                      <table class="table table-borderless">
                        <ng-container>

                          <thead *ngIf="i==0">
                            <tr>
                              <th class="text-secondary " *ngFor="let key of objectKeys(item.json_fields)">{{key}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-secondary" *ngFor="let key of objectKeys(item.json_fields)">
                                {{item.json_fields[key] }}</td>
                            </tr>

                          </tbody>
                        </ng-container>
                      </table>
                    </div>
                  </div>

                  <hr>

                </div>
                <ng-container *ngIf="methodAssocietedAlready">

                  <hr>
                </ng-container>

                <app-my-offers [constraintOptions]="constraintOptions" [newOffer]="transaction.offer"
                  [offer]="transaction.listing" [listDetails]="transaction.listing" [statusOptions]="statusOptions"
                  (updateOffers)="handleUpdateOffers()" [index]="i" [isTransaction]="true"
                  [transactionStatus]="transactionStatus">
                </app-my-offers>

              </div>
            </td>
          </tr>
          <tr id="collapse{{i}}{{transaction.listing.id}}" class=" collapse fade"
            [attr.aria-labelledby]="'heading'+i+transaction.listing.id" data-parent="#accordion">
            <td
              [attr.colspan]="listFilterBy == 'buyerPendingTransactions' || listFilterBy == 'sellerPendingTransactions' ? '9' :'6'">

              <div class="card-body border shadow-lg p-4 mb-5 bg-white rounded text-dark">
                <div class="mt-2">
                  <h5 style="color: navy;">
                    Email Notifications
                  </h5>
                </div>

                <div class="mb-3 row mt-4">
                  <label for="subject" class="col-sm-2 col-form-label fw-bold">Subject</label>
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-text py-0">{{emailNotifications.trxnId}}</span>
                      <input type="text" class="form-control" [(ngModel)]="emailNotifications.subject" id="subject"
                        name="subject">
                    </div>
                  </div>

                </div>
                <div class="mb-3 row">
                  <label for="inputPassword" class="col-sm-2 col-form-label fw-bold">Message</label>
                  <div class="col-6">
                    <textarea class="form-control" id="body" name="body" rows="5"
                      [(ngModel)]="emailNotifications.body"></textarea>
                  </div>
                </div>

                <div class="d-flex gap-3 mt-5">
                  <button type="submit" class="btn btn-warning text-white mb-2 fw-bold"
                    [attr.data-bs-target]="'#exampleModal'" data-bs-toggle="modal" (click)="handleMessage()"
                    [disabled]="(emailNotifications.body == '' || emailNotifications.subject == '')">
                    Send Notification
                  </button>

                  <button type="submit" class="btn btn-outline-dark cancel-button  mb-2 fw-bold" data-toggle="collapse"
                    [attr.data-target]="'#collapse'+i+transaction.listing.id" aria-expanded="false"
                    [attr.aria-controls]="'collapse'+i+transaction.listing.id">
                    Close Panel
                  </button>

                </div>

              </div>

            </td>
          </tr>
        </ng-container>



      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center" *ngIf="handleMyTransactionsLength() > 0">
    <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
    </pagination-controls>
  </div>
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
</ngx-spinner>



<ng-container>
  <div class="modal fade text-wrap" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel" [innerHTML]="sanitizeHTML(offerDisclaimer.value1)">
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="handleIsEmailNotification()"></button>
        </div>
        <div class="modal-body" [innerHTML]="sanitizeHTML(offerDisclaimer.value2)">
        </div>
        <div class="modal-footer">
          <div class="mr-auto">
            <ng-container *ngIf="!isEmailNotificationFlag; else isEmailNotificationFlagElseBlock">
              <button type="button" class="btn btn-warning text-white mr-2 fw-bold" data-bs-dismiss="modal"
                (click)="handleUpdateEckardTransactions(transactionCurrentList, transactionCurrentStatus)">
                YES
              </button>
            </ng-container>

            <ng-template #isEmailNotificationFlagElseBlock>
              <button type="button" class="btn btn-warning text-white mr-2 fw-bold" data-bs-dismiss="modal"
                (click)="handleSendEmailNotifications()">
                YES
              </button>
            </ng-template>
            <button type="button" class="btn btn-danger ml-2 fw-bold" data-bs-dismiss="modal"
              (click)="handleIsEmailNotification()">
              NO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>