import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginForm } from 'src/components/model/login';
import { LoginService } from 'src/components/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  isPassword: boolean = false;
  loginForm: LoginForm = {
    email: '',
    password: '',
  };
  errorMessage: string = 'User Not Found';
  isError: boolean = false;
  isloading: boolean = false;

  constructor(private router: Router, private loginService: LoginService) {}
  ngOnInit(): void {
    this.loginService.isAuthanticated =
      localStorage.getItem('isAuthanticated') == 'true' ? true : false;
    if (this.loginService.user) {
      this.router.navigate(['/market-place']);
      return;
    }
  }

  togglePassword() {
    this.isPassword = !this.isPassword;
  }

  handleSubmit() {
    this.isloading = true;
    this.loginService.login(this.loginForm).subscribe(
      (response) => {
        this.isloading = false;
        this.isError = false;
      },
      (error: any) => {
        this.isError = true;
        this.isloading = false;
        console.log('error', error);
      },
      () => console.log('Done getting user')
    );
  }
}
