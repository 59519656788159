import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotificationsModule } from '../../notifications/notifications.module';
import { BuyerNotificationsComponent } from './buyer-notifications.component';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
      path: '',
      component: BuyerNotificationsComponent
    }
  ];


@NgModule({
    declarations: [
        BuyerNotificationsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FormsModule,
        NotificationsModule
    ],
    providers: [],
    exports: [BuyerNotificationsComponent]
})
export class BuyerNotificationsModule { }
