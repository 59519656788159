<div class="accordion accordion-flush col-12 p-0" id="listingAccordion">
  <div class="accordion-item">
    <h5
      class="accordion-header collapsed p-0 est-cashflow"
      id="listing-headingOne"
      data-toggle="collapse"
      data-target="#listing-collapseOne"
      aria-expanded="false"
      aria-controls="listing-collapseOne"
    >
      Basic CashFlow Calculator
      <img
        src="assets/cash-flow.svg"
        width="24"
        height="24"
        class="m-0"
        alt="chevron-right"
        title="The Cashflow Calculator uses an exponential decline curve algorithm with customizable parameters"
      />

      <strong
        class="small float-end fw-bold est-cashflow"
        *ngIf="isRecalculate"
      >
        Est. CashFlow:
        {{
          calculateTotalCashFlow | currency : "CAD" : "symbol-narrow" : ".2-2"
        }}</strong
      >
    </h5>
    <div
      id="listing-collapseOne"
      class="accordion-collapse collapse pt-2"
      aria-labelledby="listing-headingOne"
      data-parent="#listingAccordion"
    >
      <div
        class="d-flex flex-wrap justify-content-center justify-content-lg-start px-4 gap-4 pt-4 col-12 w-100"
      >
        <div class="d-inline-block pe-auto mr-2 fw-bold">
          <span
            [ngClass]="{
              'cashFlow-type-heading': cashFlowStatus == 'Conservative'
            }"
            (click)="toggleCashFlow('Conservative')"
            >More Conservative</span
          >
        </div>
        <div class="d-inline-block pe-auto ml-lg-2 fw-bold">
          <span
            [ngClass]="{
              'cashFlow-type-heading': cashFlowStatus == 'Standard'
            }"
            (click)="toggleCashFlow('Standard')"
            >Standard</span
          >
        </div>
        <div class="d-inline-block pe-auto ml-lg-2 fw-bold">
          <span
            [ngClass]="{
              'cashFlow-type-heading': cashFlowStatus == 'Assertive'
            }"
            (click)="toggleCashFlow('Assertive')"
            >More Assertive</span
          >
        </div>
        <div class="d-inline-block pe-auto ml-lg-2 fw-bold">
          <span
            [ngClass]="{
              'cashFlow-type-heading': cashFlowStatus == 'Defaults'
            }"
            (click)="toggleCashFlow('Defaults')"
            >Defaults</span
          >
        </div>
      </div>

      <div
        class="accordion-body d-flex flex-wrap justify-content-center justify-content-md-between px-0 pt-4 col-12 w-100"
      >
        <div class="d-flex flex-column col-12 col-md">
          <div>
            <label for="months" class="form-label"># of Months</label>
            <input
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              class="form-control"
              aria-label="# of Months"
              [(ngModel)]="basicCashFlow.months"
              id="months"
              name="months"
            />
          </div>
        </div>
        <div class="d-flex flex-column col-12 col-md">
          <div>
            <label for="expectedOutputDecline" class="form-label"
              >Exp. Decline[%]</label
            >
            <input
              type="number"
              class="form-control"
              aria-label="Expected Output Decline(%)"
              min="0"
              [(ngModel)]="basicCashFlow.decline"
              id="decline"
              name="decline"
            />
          </div>
        </div>
        <div class="d-flex flex-column col-12 col-md">
          <div>
            <label for="oil" class="form-label">Oil Price [BBL]</label>
            <input
              type="number"
              min="0"
              class="form-control"
              aria-label="Oil Price"
              [(ngModel)]="basicCashFlow.oil"
              id="oil"
              name="oil"
            />
          </div>
        </div>
        <div class="d-flex flex-column col-12 col-md">
          <div>
            <label for="gas" class="form-label">Gas Price [MCF]</label>
            <input
              type="number"
              min="0"
              class="form-control"
              aria-label="Gas Price"
              [(ngModel)]="basicCashFlow.gas"
              id="gas"
              name="gas"
            />
          </div>
        </div>
      </div>

      <div class="d-flex flex-row gap-3 px-4 float-end">
        <div class="">
          <span
            for="default"
            class="form-label d-inline-block text-primary pe-auto border-primary"
            (click)="handleSaveAsDefault(basicCashFlow)"
          >
            Save as default
          </span>
        </div>
        <div class="">
          <span
            for="recalculate"
            class="form-label d-inline-block text-primary pe-auto border-primary"
            (click)="handleCalculateCashFlow()"
          >
            Re-calculate
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
