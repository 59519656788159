import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TransactionsModule } from '../../transactions/transaction.module';
import { SellerTransactionsComponent } from './seller-transactions.component';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
      path: '',
      component: SellerTransactionsComponent
    }
  ];

@NgModule({
    declarations: [
        SellerTransactionsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FormsModule,
        TransactionsModule
    ],
    providers: [],
    exports: [SellerTransactionsComponent]
})
export class SellerTransactionsModule { }
