import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Constraint, EmailNotifications, Status, Transaction } from 'src/components/model/my-listings';
import { LoginService } from 'src/components/services/login.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { AddNewListingService } from '../../services/add-new-listing.service';
import { MatSort } from '@angular/material/sort';
import { OfferConfirmMessages } from 'src/components/model/login';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/components/services/common.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-transactions',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
})
export class TransactionsComponent implements OnInit {
  @Input() transactionStatus!: string;
  @Input() listFilterBy!: string;

  page: number = 1;
  count: number = 0;
  tableSize: number = 50;
  tableSizes: number[] = [3, 6, 9, 12];
  transactionColumns: string[] = [];
  listDetails = [];
  offer = [];
  newOffer = [];
  methodsColumns: Array<String> = ['Method Type', 'Method Information'];
  methodAssocietedAlready = false;
  constraintOptions!: Constraint[];
  statusOptions!: Status[];
  accountsMethods: any = [];
  pendingsTransactions!: Transaction[];
  showData!: boolean[];
  showData1!: boolean[];
  offerConfirmMessages!: OfferConfirmMessages[];
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: ''
  };
  transactionCurrentList!: Transaction;
  transactionCurrentStatus!: string;
  emailNotifications: EmailNotifications = {
    trxnId: '',
    subject: '',
    body: '',
    listing_contact_id: '',
    offer_contact_id: '',
    recipient_id: '',
    type_of_recipient: '',
  };
  isEmailNotificationFlag: boolean = false;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private addNewListingService: AddNewListingService,
    private myOffersService: MyOffersService,
    private toastr: ToastrService,
    private myListingsService: MyListingsService,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService

  ) {
    this.showData = [];
    this.showData1 = [];
  }

  ngOnInit(): void {
    this.updateTransactionColumns();
    this.handleConstraint();
    this.handleGetStatus();
    this.handleOfferDealMessages();
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  updateTransactionColumns() {
    if (this.transactionStatus === 'Sell') {
      this.spinner.show();

      if (
        this.listFilterBy == 'sellerPendingTransactions' ||
        this.listFilterBy == 'buyerPendingTransactions'
      ) {
        this.transactionColumns = [
          'Trxn ID',
          'Project',
          'NMA',
          'Price',
          'Buyer',
          'Action',
          'Status',
          'Deal Date',
          'Progress',
        ];
      } else {
        this.transactionColumns = [
          'Trxn ID',
          'Project',
          'NMA',
          'Price',
          'Buyer',
          'Transfer Date',
        ];
      }

      this.handleGetSellerTransactions();
    } else {
      this.spinner.show();

      this.handleGetBuyingTransactions();
      if (
        this.listFilterBy == 'buyerPendingTransactions' ||
        this.listFilterBy == 'sellerPendingTransactions'
      ) {
        this.transactionColumns = [
          'Trxn ID',
          'Project',
          'NMA',
          'Price',
          'Seller',
          'Action',
          'Status',
          'Deal Date',
          'Progress',
        ];
      } else {
        this.transactionColumns = [
          'Trxn ID',
          'Project',
          'NMA',
          'Price',
          'Seller',
          'Transfer Date',
        ];
      }
    }
  }
  objectKeys(obj: any) {
    return Object.keys(obj);
  }

  associateMethod(item: any) {
    this.spinner.show();

    //     this.pendingsTransactions.map((res: any) => {

    // const hasId= res.fund_transfer_method.filter((re:any)=>parseInt(item.id)==parseInt(re.id));
    // alert(JSON.stringify(hasId))

    //     })

    this.loginService
      .associateTransferMethod({
        fund_transfer_method: parseInt(item.id),
        transaction: parseInt(item.transactionId),
      })
      .subscribe((response: any) => {
        this.pendingsTransactions.map((res: any) => {
          if (res.id == item.transactionId) {
            res.fund_transfer_method.push({
              ...response,
              id: response.fund_transfer_method,
            });
            this.clickTransaction(res);
          }
        });

        this.spinner.hide();
      });
  }

  groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  clickTransaction(transaction: any) {
    this.methodAssocietedAlready = false;
    const transferMethods: any = [];
    const transferMethodsCopy: any = [];

    if (this.transactionStatus === 'Sell') {
      this.spinner.show();
      this.loginService
        .getAccountMethods(this.loginService.user.id)
        .subscribe((response: any) => {
          let filterTramsactionsMethods = response?.filter(
            (method: any) => method.account.id == transaction.listing.account.id
          );
          filterTramsactionsMethods?.map((res: any) => {
            res.transactionId = transaction.id;
            const hasMethodAssociated = transaction.fund_transfer_method.filter(
              (account: any) => parseInt(account.id) == parseInt(res.id)
            );
            let methodsInfo: any = {};
            if (res.type == 'Check') {
              methodsInfo['Eckard Account'] = res.account.accountName;
              methodsInfo['Account Holder'] = res.json_fields['Recipient'];
              let mailTo = '';
              if (res.json_fields['mailto']) {
                mailTo += res.json_fields['mailto'] + ', ';
              }
              if (res.json_fields['Street']) {
                mailTo += res.json_fields['Street'] + ', ';
              }
              if (res.json_fields['City']) {
                mailTo += res.json_fields['City'] + ', ';
              }
              if (res.json_fields['State']) {
                mailTo += res.json_fields['State'] + ' ';
              }
              if (res.json_fields['Zip']) {
                mailTo += res.json_fields['Zip'] + ', ';
              }
              if (res.json_fields['country']) {
                mailTo += res.json_fields['country'];
              }
              methodsInfo['Mail To'] = mailTo;
            } else {
              methodsInfo['Eckard Account'] = res.account.accountName;
              methodsInfo['Account Holder'] = res.json_fields['Recipient'];
              methodsInfo['Account Number'] = res.json_fields['Account_Number'];
              methodsInfo['Bank Name'] = res.json_fields['Bank_Name'];
              methodsInfo['ABA Routing Number'] =
                res.json_fields['Routing_Number'];
            }

            if (hasMethodAssociated.length > 0) {
              this.methodAssocietedAlready = true;
              res.current = true;
              transferMethodsCopy.push({
                ...res,
                json_fields: methodsInfo,
              });
            }
            transferMethods.push({
              ...res,
              json_fields: methodsInfo,
            });
          });
          this.spinner.hide();
          const groupByType =
            transferMethodsCopy?.length > 0
              ? this.groupBy(transferMethodsCopy, 'type')
              : this.groupBy(transferMethods, 'type');

          this.accountsMethods = groupByType;
        });
    }
    // alert(JSON.stringify(transaction.account))
  }
  handleGetSellerTransactions() {
    let offset = (this.page - 1) * this.tableSize;
    this.myListingsService
      .handleGetSellerPendingTransactions(
        this.loginService.user.id,
        this.tableSize,
        offset,
        this.listFilterBy
      )
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.pendingsTransactions = response?.data;
          this.page = response?.metadata?.page;
          this.count = response?.metadata?.total_results;
          this.tableSize = response?.metadata?.limit;
        },
        (error: any) => {
          this.spinner.hide();

          console.log('Error getting list details', error);
        },
        () => console.log('Done getting list details')
      );
  }

  handleGetBuyingTransactions() {
    let offset = (this.page - 1) * this.tableSize;
    this.myListingsService
      .handleGetBuyerPendingTransactions(
        this.loginService.user.id,
        this.tableSize,
        offset,
        this.listFilterBy
      )
      .subscribe(
        (response) => {
          this.spinner.hide();

          response?.data?.map((ress: any) => {
            ress.fund_transfer_method.map((res: any) => {
              let methodsInfo: any = {};
              if (res.type == 'Check') {
                methodsInfo['Account Holder'] = res.json_fields['Recipient'];
                let mailTo = '';
                if (res.json_fields['mailto']) {
                  mailTo += res.json_fields['mailto'] + ', ';
                }
                if (res.json_fields['Street']) {
                  mailTo += res.json_fields['Street'] + ', ';
                }
                if (res.json_fields['City']) {
                  mailTo += res.json_fields['City'] + ', ';
                }
                if (res.json_fields['State']) {
                  mailTo += res.json_fields['State'] + ' ';
                }
                if (res.json_fields['Zip']) {
                  mailTo += res.json_fields['Zip'] + ', ';
                }
                if (res.json_fields['country']) {
                  mailTo += res.json_fields['country'];
                }
                methodsInfo['Mail To'] = mailTo;
              } else {
                methodsInfo['Account Holder'] = res.json_fields['Recipient'];
                methodsInfo['Account Number'] =
                  res.json_fields['Account_Number'];
                methodsInfo['Bank Name'] = res.json_fields['Bank_Name'];
                methodsInfo['ABA Routing Number'] =
                  res.json_fields['Routing_Number'];
              }

              res.json_fields = methodsInfo;
            });
          });

          this.pendingsTransactions = response?.data;
          this.page = response?.metadata?.page;
          this.count = response?.metadata?.total_results;
          this.tableSize = response?.metadata?.limit;
        },
        (error: any) => {
          this.spinner.hide();

          console.log('Error getting list details', error);
        },
        () => console.log('Done getting list details')
      );
  }

  toggleListing(type: any) {
    this.page = 1;
    this.count = 0;
    this.tableSize = 50;
    this.transactionStatus = type;
    this.pendingsTransactions = [];
    this.updateTransactionColumns();
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.updateTransactionColumns();
  }

  handleMyTransactionsLength() {
    if (this.pendingsTransactions) {
      return this.pendingsTransactions.length;
    }
    return 0;
  }

  handleConstraint() {
    this.addNewListingService.handleConstraint().subscribe(
      (response: any) => {
        const buyOptions: any = [];
        response?.map((item: any) => {
          if (item.buyLabel) {
            buyOptions.push({ ...item, isChecked: false });
          }
        });
        this.constraintOptions = buyOptions;
      },
      (error: any) => {
        console.log('Error getting buyer Constraint', error);
      },
      () => console.log('Done getting buyer Constraint')
    );
  }

  handleGetStatus() {
    this.addNewListingService.handleGetStatus().subscribe(
      (response: any) => {
        this.statusOptions = response;
      },
      (error: any) => {
        console.log('Error getting status', error);
      },
      () => console.log('Done getting status ')
    );
  }

  handleUpdateOffers() {
    this.updateTransactionColumns();
  }

  toggleData(index: number) {
    // Toggle the value of the showData[index] variable
    this.showData[index] = !this.showData[index];
  }

  toggleData1(index: number) {
    // Toggle the value of the showData[index] variable
    this.showData1[index] = !this.showData1[index];
  }

  handleOfferDealMessages() {
    this.myOffersService.handleOfferDealMessages().subscribe(
      (response: any) => {
        this.offerConfirmMessages = response;
      },
      (error: any) => {
        console.error('Error getting key vlaue  : ', error);
      },
      () => console.log('Done getting key vlaue .')
    );
  }

  handleAlertMessage() {
    let message = this.offerConfirmMessages?.filter(
      (item: any) => item.key == environment.SELECT_FTM_KEY
    );
    this.offerDisclaimer = message[0];
  }

  handleAlertTransactionsMessage(obj: Transaction, type: any) {
    this.isEmailNotificationFlag = false;
    this.transactionCurrentStatus = type;
    this.transactionCurrentList = obj;
    if (type == environment.TRANSACTION_PSA_FULLY_EXECUTED) {
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.FUND_TRANSFER_INITIATED_DISCLAIMER
      );
      this.offerDisclaimer = message[0];
    } else if (type == environment.TRANSACTION_FUND_TRANSFER_CONFIRMED) {
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.CONFIRM_FUNDS_RECEIVED_DISCLAIMER
      );
      this.offerDisclaimer = message[0];
    }
  }

  handleUpdateEckardTransactions(transaction: any, type: any) {
    this.spinner.show();
    
    if (transaction.offer.account == null) {
      this.spinner.hide();
      this.toastr.info(
        `${
          type == environment.TRANSACTION_FUND_TRANSFER_CONFIRMED
            ? environment.BUYER_NOT_ASSOCIATED_ACCOUNT_WARNING
            : environment.ASSOCIATE_ACCOUNT_WITH_OFFER_WARNING
        }`
      );
      return;
    }

    if (transaction.fund_transfer_method.length == 0 && type == environment.TRANSACTION_FUND_TRANSFER_CONFIRMED ){
      this.spinner.hide();
      this.toastr.info(environment.ASSOCIATE_FTM_WITH_LIST_WARNING);
      return;
    }
    
    if (type == environment.TRANSACTION_PSA_FULLY_EXECUTED) {
      transaction.status = this.statusOptions?.find(
        (item: any) => item.status == environment.FUND_TRANSFER_INITIATED_DISCLAIMER
      );
    }
    if (type == environment.TRANSACTION_FUND_TRANSFER_CONFIRMED) {
      transaction.status = this.statusOptions?.find(
        (item: any) => item.status == environment.TRANSACTION_FUND_TRANSFER_CONFIRMED
      );
    }
    this.myListingsService.handleGetTransactions(transaction).subscribe(
      (response: any) => {
        if (response.status.status != environment.TRANSACTION_FUND_TRANSFER_CONFIRMED ) {
          this.myListingsService
            .handleUpdateEckardTransactions(transaction)
            .subscribe(
              (response: any) => {
                this.spinner.hide();
                this.updateTransactionColumns();
                this.toastr.success(environment.TRANSACTION_UPDATE_STATUS_MESSAGE);
              },
              (error: any) => {
                this.spinner.hide();
                console.log('Error getting Update Eckard Transactions', error);
              },
              () => console.log('Done getting Update Eckard Transactions ')
            );
        } else {
          this.spinner.hide();
          this.updateTransactionColumns();
          this.toastr.info(environment.SELLER_ALREADY_CONFIRM_FUNDS_RECEIVED_MESSAGE);
        }
      },
      (error: any) => {
        this.spinner.hide();
        console.log('Error getting Update Eckard Transactions', error);
      },
      () => console.log('Done getting Update Eckard Transactions ')
    );
  }

  handleFTMAccepted(list: any) {
    if (list?.status?.status == 'Accepted') {
      return true;
    }

    return false;
  }

  onSortChange(event: any): void {
    let sortProperty = '';
    switch (event.active) {
      case 'Trxn ID':
        sortProperty = 'trnxId';
        break;
      case 'Project':
        sortProperty = 'listing.project.projectId';
        break;
      case 'NMA':
        sortProperty = 'nma';
        break;
      case 'Price':
        sortProperty = 'trans_amount';
        break;
      case 'Buyer':
        sortProperty = 'offer.contact.mpName';
        break;
      case 'Seller':
        sortProperty = 'listing.account.mpName';
        break;
      case 'Status':
        sortProperty = 'status.statusLabel';
        break;
      case 'Transfer Date':
        sortProperty = 'transfer_date';
        break;
      case 'Deal Date':
        sortProperty = 'deal_date';
        break;
      default:
        return;
    }
    const sortOrder = event.direction === 'asc' ? 1 : -1;

    this.pendingsTransactions = this.pendingsTransactions.sort(
      (a: any, b: any) => {
        const aValue = this.getPropertyValue(a, sortProperty);
        const bValue = this.getPropertyValue(b, sortProperty);

        if (aValue < bValue) {
          return -1 * sortOrder;
        } else if (aValue > bValue) {
          return 1 * sortOrder;
        } else {
          return 0;
        }
      }
    );
  }

  getPropertyValue(object: any, propertyPath: string): any {
    const props = propertyPath.split('.');
    let value = object;
    for (const prop of props) {
      value = value[prop];
    }
    return value;
  }

  handleEmailNotifications(obj: any) {
    this.handleClearNotifications();
    let message = this.offerConfirmMessages?.filter(
      (item: any) => item.key == environment.NOTIFICATION_DISCLAIMER
    );
    this.offerDisclaimer = message[0];
    this.emailNotifications.trxnId = 'Trxn ' + obj.trnxId;
    this.emailNotifications.listing_contact_id = obj.listing.account.contact.id;
    this.emailNotifications.offer_contact_id = obj.offer.contact.id;
    this.emailNotifications.recipient_id =
      this.transactionStatus != 'Sell'
        ? obj.listing.account.contact.id
        : obj.offer.contact.id;
    this.emailNotifications.type_of_recipient =
      this.transactionStatus == 'Sell' ? 'buyer' : 'seller';
  }

  handleClearNotifications() {
    this.emailNotifications.trxnId = '';
    this.emailNotifications.subject = '';
    this.emailNotifications.body = '';
    this.emailNotifications.listing_contact_id = '';
    this.emailNotifications.offer_contact_id = '';
    this.emailNotifications.recipient_id = '';
    this.emailNotifications.type_of_recipient = '';
  }

  handleIsEmailNotification() {
    this.isEmailNotificationFlag = !this.isEmailNotificationFlag;
  }

  handleSendEmailNotifications() {
    this.isEmailNotificationFlag = !this.isEmailNotificationFlag;
    this.myListingsService
      .handleTransactionsNotifications(this.emailNotifications)
      .subscribe(
        (response: any) => {
          this.updateTransactionColumns();
          this.spinner.hide();
          this.toastr.success(
            `Notifications has been sent to ${this.emailNotifications.type_of_recipient}.`
          );
        },
        (error: any) => {
          this.spinner.hide();
          this.toastr.error(
            `Notifications not sent to ${this.emailNotifications.type_of_recipient}.`,
            '',
            {
              progressBar: false,
              tapToDismiss: false,
              disableTimeOut: true,
            }
          );
          console.log('Error  create Notifications Transactions', error);
        },
        () => console.log('Done  create Notifications Transactions ')
      );
  }

  handleMessage() {
    this.handleIsEmailNotification();
    let message = this.offerConfirmMessages?.filter(
      (item: any) => item.key == environment.NOTIFICATION_DISCLAIMER
    );
    this.offerDisclaimer = message[0];
  }
}
