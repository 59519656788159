<ng-container *ngIf="createNewListing.listing_type">

    <ng-container *ngIf="createNewListing.listing_type == 1">
        <div class="row mt-4">

            <div class="col-md-6">
                <label for="account" class="form-label">Account <span class="text-danger">*</span></label>
                <select id="account" class="form-select" [(ngModel)]="createNewListing.account"
                    (ngModelChange)="handleChange('account')" #account="ngModel"
                    [ngClass]="{'border border-danger':account.invalid && (account.dirty || account.touched)}" required
                    [disabled]="!isListDraft">
                    <option *ngFor="let item of accountsOptions " [value]="item.id">{{item.accountName}} </option>
                </select>

                <span *ngIf="account.invalid && (account.dirty || account.touched ) &&  account.errors"
                    class="text-danger rounded px-4 error-message">
                    Please select valid account
                </span>
            </div>
            <div class="col-md-6" *ngIf="handleProjectLength() > 0">
                <div class="d-flex">

                    <label for="project" class="form-label">Project <span class="text-danger">*</span></label>
                    <label class="text-primary ml-auto"><a *ngIf="createNewListing.project"> Map <i
                                class="fa fa-user"></i></a></label>

                </div>


                <select id="project" class="form-select" [(ngModel)]="createNewListing.project"
                    (ngModelChange)="handleChange('project')" #project="ngModel" [compareWith]="auctionTypeComparator"
                    [ngClass]="{'border border-danger':project.invalid && (project.dirty || project.touched)}" required
                    [disabled]="!isListDraft">
                    <option disabled value="null" hidden></option>
                    <option *ngFor="let item of projectsOptions " [ngValue]="item"
                        [disabled]="item.blockException || item.blockedUntil > createNewListing.listingStart">
                        {{item.projectId}} <span
                            *ngIf=" item.blockException || item.blockedUntil > createNewListing.listingStart"
                            class="project-blocked">(blocked)</span>
                    </option>
                </select>

                <span *ngIf="project.invalid && (project.dirty || project.touched ) &&  project.errors"
                    class="text-danger rounded px-4 error-message">
                    Please select valid project
                </span>
            </div>
        </div>
        <div class="mt-3 row" *ngIf="createNewListing.project">

            <div class=" col-md-6">
                <label for="nma" class="form-label">NMA Listed <small class="text-muted" *ngIf="isListDraft">(available:
                        <ng-container *ngIf="incomeListing">{{incomeListing.availableNma}}, minimum:
                            {{incomeListing.minimumNma}}</ng-container>
                        NMA)
                    </small> <span class="text-danger">*</span></label>
                <input type="number" class="form-control" [(ngModel)]="createNewListing.nma" min="0" step="any"
                    oninput="validity.valid||(value='');" (ngModelChange)="handleChange('nma')" id="nma" name="nma"
                    #nma="ngModel"
                    [ngClass]="{'border border-danger': nma.invalid && (nma.dirty || nma.touched) ||  handleIsValidNma() && !(isListEdit && !isListDraft) }"
                    required [disabled]="!isListDraft">
                <span
                    *ngIf="nma.invalid && (nma.dirty || nma.touched ) &&  nma.errors  || handleIsValidNma() && !(isListEdit && !isListDraft)"
                    class="text-danger rounded px-4 error-message">
                    {{nmaErrorMessage}}
                </span>
            </div>
            <div class="col-md-6 d-flex flex-column pt-4" *ngIf="listingCost && createNewListing.nma">
                <div class="align-items-center text-secondary">
                    <small>Listing Cost:
                        {{(listingCost.costPerNma*createNewListing.nma) |
                        currency:'CAD':'symbol-narrow':'.2-2'}} ({{listingCost.costPerNma |
                        currency:'CAD':'symbol-narrow':'.2-2'}} per NMA)</small>
                </div>
                <div class="align-items-center text-secondary">
                    <small *ngIf="incomeListing">Listing Income To Date:
                        {{((incomeListing.incomeToDate/incomeListing.totalNma)*createNewListing.nma) |
                        currency:'CAD':'symbol-narrow':'.2-2'}}
                        ({{(((incomeListing.incomeToDate/incomeListing.totalNma)*createNewListing.nma)/(listingCost.costPerNma*createNewListing.nma
                        )) | percent:'.2-2'}} ROI) </small>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="createNewListing.listing_type == 2 ">

        <ng-container *ngIf="handleTractLength() > 0">
            <p class="mt-4 "><strong>Tracts in Listing ({{handleTractLength()}})</strong> </p>
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-secondary" scope="col" *ngFor="let item of listingColumns;">{{item}}
                        </th>
                    </tr>
                </thead>
                <tbody class="my-listing-table-body">
                    <tr *ngFor="let tract of tracts">
                        <td> <span class="data-details">{{tract.tractId}}</span></td>
                        <td></td>
                        <td>{{tract.country}}, {{tract.state}}</td>
                        <td></td>
                        <td>{{tract.royalityInterest}}%</td>
                        <td>{{tract.costPerNma}}</td>
                        <td>0.7434</td>
                        <td><span class="data-details float-end">All</span></td>
                        <td class="text-muted">100%</td>
                        <td> 1,232.09</td>
                    </tr>
                    <tr>
                        <th colspan="5"> Cost of Listed Tracts : $10,534.98 </th>
                        <td>0.7434</td>
                        <td>0.8377</td>
                        <td class="text-end">0.2323</td>
                        <td>test</td>
                        <td> 1,232.09</td>
                        <td> 29%</td>
                        <td> ROI</td>

                    </tr>

                </tbody>
            </table>
        </ng-container>

        <ng-container *ngIf="handleTractLength() == 0">
            <p class="text-warning"><strong>Please go to
                    <span class=" pe-auto border-bottom border-warning" (click)="handleRemoveAndAddClass()"
                        title="Add New Tracts">
                        Add Tracts</span> to identify specific
                    Tracts to be added to
                    this Listing</strong>

            </p>
        </ng-container>

    </ng-container>
    
    <ng-container *ngIf="!(handleTractLength() == 0 && createNewListing.listing_type == 2)">
        <ng-container *ngIf="createNewListing.project && createNewListing.nma && createNewListing.auction_type">
            <div class="mt-3 row ">
                <div class=" col-md-6">
                    <label for="minimumAsk" class="form-label">{{createNewListing.auction_type.auctionType ==
                        'Fix Price' ? 'Fix Price' :
                        (createNewListing.auction_type.auctionType == 'Direct Sale' ? 'Direct Sale Price' :
                        'Minimum Asking Price')}} <span class="text-danger">*</span></label>
                    <input class="form-control " currencyMask
                        [options]="{ prefix: '$', thousands: ',',precision: !isListDraft ? 2 : 0, align:'left', allowNegative: false}"
                        [(ngModel)]="createNewListing.minimumAsk" (ngModelChange)="handleChange('minimumAsk')"
                        name="minimumAsk" id="minimumAsk" #minimumAsk="ngModel"
                        [ngClass]="{'border border-danger':minimumAsk.invalid && (minimumAsk.dirty || minimumAsk.touched)}"
                        required [disabled]="!isListDraft">
                    <span *ngIf="minimumAsk.invalid && (minimumAsk.dirty || minimumAsk.touched ) &&  minimumAsk.errors"
                        class="text-danger rounded px-4 error-message">
                        {{createNewListing.auction_type.auctionType == 'Fix Price' ? 'Please enter Fix Price' :
                        (createNewListing.auction_type.auctionType == 'Direct Sale' ? 'Please enter Direct Sale Price' :
                        'Please enter Minimum Asking Price')}}
                    </span>
                </div>
                <div class="col-md-6 d-flex flex-column pt-4"
                    *ngIf="listingCost && createNewListing.nma && createNewListing.minimumAsk">
                    <div class="align-items-center text-secondary">
                        <small>
                            {{(createNewListing.minimumAsk/createNewListing.nma) |
                            currency:'CAD':'symbol-narrow':'.2-2'}} per NMA</small>
                    </div>
                    <div class="align-items-center text-secondary">
                        <small *ngIf="incomeListing">Profit on Exit:
                            {{ (createNewListing.minimumAsk -
                            (listingCost.costPerNma*createNewListing.nma)) |
                            currency:'CAD':'symbol-narrow':'.2-2'}}, Total Profit: {{(((createNewListing.minimumAsk -
                            (listingCost.costPerNma*createNewListing.nma)) +
                            ((incomeListing.incomeToDate/incomeListing.totalNma)*createNewListing.nma))) |
                            currency:'CAD':'symbol-narrow':'.2-2'}}
                            ({{ ((((createNewListing.minimumAsk -
                            (listingCost.costPerNma*createNewListing.nma)) +
                            ((incomeListing.incomeToDate/incomeListing.totalNma)*createNewListing.nma)))/(listingCost.costPerNma*createNewListing.nma))
                            | percent:'.2-2'}} ROI)
                        </small>
                    </div>


                </div>
            </div>
            <div class="mt-3 row "
                *ngIf="createNewListing.auction_type && createNewListing.auction_type.auctionType == 'Direct Sale' && createNewListing.directSaleToken">
                <div class=" col-md-6">
                    <label for="minimumAsk" class="form-label">Direct Sale URL</label>
                    <p class="px-2 pe-auto"> http://52.24.239.223/direct-sale/{{createNewListing.directSaleToken}}
                    </p>
                </div>
            </div>
            <ng-container
                *ngIf="createNewListing.auction_type && createNewListing?.auction_type?.auctionType?.endsWith('- Buy Now or Make an Offer')">
                <div class="mt-3 row ">

                    <div class=" col-md-6">
                        <label for="Immediately" class="form-label"> Buy Immediately Price <span
                                class="text-danger">*</span></label>
                        <p class="font-italic my-0"><small>Transaction will trigger immediately if a Buyer offers the
                                'Buy
                                Immediately'
                                price</small></p>
                        <input class="form-control text-start" [(ngModel)]="createNewListing.buyNowPrice"
                            (ngModelChange)="handleChange('buyNowPrice')" name="buyNowPrice" id="buyNowPrice"
                            #buyNowPrice="ngModel"
                            [ngClass]="{'border border-danger':buyNowPrice.invalid && (buyNowPrice.dirty || buyNowPrice.touched)}"
                            required [disabled]="!isListDraft" currencyMask
                            [options]="{ prefix: '$', thousands: ',', precision: !isListDraft ? 2 : 0, align:'left', allowNegative: false}">
                        <span
                            *ngIf="buyNowPrice.invalid && (buyNowPrice.dirty || buyNowPrice.touched ) &&  buyNowPrice.errors"
                            class="text-danger rounded px-4 error-message">

                            Please enter Buy Immediately Price
                        </span>
                    </div>
                    <div class="col-md-6 d-flex flex-column pt-5 m-auto"
                        *ngIf="listingCost && createNewListing.nma && createNewListing.buyNowPrice">
                        <div class="align-items-center text-secondary">
                            <small>
                                {{(createNewListing.buyNowPrice/createNewListing.nma) |
                                currency:'CAD':'symbol-narrow':'.2-2'}} per NMA</small>
                        </div>
                        <div class="align-items-center text-secondary">
                            <small *ngIf="incomeListing">Profit on Exit:
                                {{ (createNewListing.buyNowPrice -
                                (listingCost.costPerNma*createNewListing.nma)) |
                                currency:'CAD':'symbol-narrow':'.2-2'}}, Total Profit:
                                {{(((createNewListing.buyNowPrice
                                -
                                (listingCost.costPerNma*createNewListing.nma)) +
                                ((incomeListing.incomeToDate/incomeListing.totalNma)*createNewListing.nma))) |
                                currency:'CAD':'symbol-narrow':'.2-2'}}
                                ({{ ((((createNewListing.buyNowPrice -
                                (listingCost.costPerNma*createNewListing.nma)) +
                                ((incomeListing.incomeToDate/incomeListing.totalNma)*createNewListing.nma)))/(listingCost.costPerNma*createNewListing.nma))
                                | percent:'.2-2'}} ROI)
                            </small>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="mt-5 row p-0">
                <app-cash-flow-calculator-tab [offerConfirmMessages]="offerConfirmMessages" [createNewListing]="createNewListing" ></app-cash-flow-calculator-tab>
            </div>
        </ng-container>

    </ng-container>


</ng-container>