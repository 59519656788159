import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EckardStaffPlaceComponent } from './eckard-staff-place.component';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [EckardStaffPlaceComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    RouterModule
  ],
  providers: [],
  exports: [EckardStaffPlaceComponent]

})
export class EckardStaffPlaceModule { }
