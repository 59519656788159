import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ListingDetailsModule } from '../buyer/my-bids/listing-details/listing-details.module';
import { MyOffersDetailsModule } from '../buyer/my-bids/my-offers/my-offers.module';
import { NotificationsComponent } from './notifications.component';
import { BreadcrumbModule } from 'src/components/breadcrumb/breadcrumb-container.module';
import { MaterialModule } from 'src/common/material.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    NgxPaginationModule,
    ListingDetailsModule,
    MyOffersDetailsModule,
    NgxSpinnerModule,
    BreadcrumbModule,
    MaterialModule,
  ],
  providers: [],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
