import { Component, Input, OnInit } from '@angular/core';
import { CashConfig, CashFlow, MyListing } from 'src/components/model/my-listings';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/components/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OfferConfirmMessages } from 'src/components/model/login';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-cash-flow-calculator-tab',
  templateUrl: './cash-flow-calculator.component.html',
  styleUrls: ['./cash-flow-calculator.component.scss'],
})
export class CashFlowCalculatorComponent implements OnInit {

  @Input() offerConfirmMessages!: OfferConfirmMessages[];
  @Input() createNewListing!: MyListing;

  cashFlow!: CashFlow;
  calculateTotalCashFlow: number = 0;
  isRecalculate: boolean = false;
  cashFlowStatus: string = '';
  isDefaults: boolean = false;
  basicCashFlow: CashConfig = {
    id: null,
    months: 0,
    decline: 0,
    gas: 0,
    oil: 0,
    contact: null,
  };

  constructor(
    private myListingsService: MyListingsService,
    private toastr: ToastrService,
    private loginService: LoginService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (this.createNewListing.account && this.createNewListing.project) {
      this.handleGetCashFlow();
      this.handleGetCashConfig();
    }
  }

  handleGetCashFlow() {
    this.myListingsService
      .handleGetCashFlow(
        this.createNewListing.project.id
      )
      .subscribe(
        (response: any) => {
          this.cashFlow = response;
        },
        (error: any) => {
          console.error('Error getting listing cost : ', error);
        },
        () => console.log('Done getting listing cost .')
      );
  }

  handleGetCashConfig() {
    this.myListingsService
      .handleGetCashConfig(
        this.loginService.user.id
      )
      .subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.isDefaults = false;
            this.cashFlowStatus = environment.CASHFLOW_TYPE_DEFAULTS;
            this.basicCashFlow.id = response[0].id;
            this.basicCashFlow.contact = response[0].contact.id;
            this.basicCashFlow.months = response[0].json_fields.months;
            this.basicCashFlow.decline = response[0].json_fields.decline;
            this.basicCashFlow.oil = response[0].json_fields.oil;
            this.basicCashFlow.gas = response[0].json_fields.gas;
          } else {
            if (this.isDefaults) {
              this.toastr.info(environment.CASHFLOW_DEFAULT_VALUE_WARNING);
            }
            this.isDefaults = false;

            this.cashFlowStatus = environment.CASHFLOW_TYPE_STANDARD;
            let standard = this.offerConfirmMessages?.filter((item: any) =>
              item.key.endsWith(environment.CASHFLOW_TYPE_STANDARD)
            );
            this.handleBasicCashFlow(standard);
          }
        },
        (error: any) => {
          console.error('Error getting listing cost : ', error);
        },
        () => console.log('Done getting listing cost .')
      );
  }
  
  handleSaveAsDefault(value: any) {
    this.spinner.show();
    value.contact = this.loginService.user.id;
    if (value.id) {
      this.handleUpdateCashConfig(value);
    } else {
      delete value.id;
      this.handleCreateCashConfig(value);
    }
  }

  handleCreateCashConfig(body: any) {
    this.myListingsService
      .handleCreateCashConfig(body)
      .subscribe(
        (response) => {
          this.spinner.hide();
          if (response) {
            this.handleGetCashConfig();
          }
          this.toastr.success(environment.CASHFLOW_ADD_DEFAULT_VALUE_SUCCESS_MESSAGE);
        },
        (error: any) => {
          this.spinner.hide();

          console.error('Error getting cash Config : ', error);
        },
        () => console.log('Done getting cash Config .')
      );
  }

  handleUpdateCashConfig(body: any) {
    this.myListingsService
      .handleUpdateCashConfig(body)
      .subscribe(
        (response) => {
          this.spinner.hide();
          if (response) {
            this.handleGetCashConfig();
            this.toastr.success(environment.CASHFLOW_UPDATE_DEFAULT_VALUE_SUCCESS_MESSAGE);
          }
        },
        (error: any) => {
          this.spinner.hide();

          console.error('Error getting cash Config : ', error);
        },
        () => console.log('Done getting cash Config .')
      );
  }

  handleCalculateCashFlow() {
    this.isRecalculate = true;
    this.calculateTotalCashFlow =
      this.myListingsService.handleCalculateCashFlow(
        this.loginService.user,
        this.basicCashFlow,
        this.cashFlow,
        this.createNewListing
      );
  }

  toggleCashFlow(type: any) {
    this.cashFlowStatus = type;
    switch (this.cashFlowStatus) {
      case environment.CASHFLOW_TYPE_CONSERVATIVE:
        let conservative = this.offerConfirmMessages?.filter((item: any) =>
          item.key.endsWith(environment.CASHFLOW_TYPE_CONSERVATIVE)
        );
        this.handleBasicCashFlow(conservative);

        break;
      case environment.CASHFLOW_TYPE_STANDARD:
        let standard = this.offerConfirmMessages?.filter((item: any) =>
          item.key.endsWith(environment.CASHFLOW_TYPE_STANDARD)
        );
        this.handleBasicCashFlow(standard);
        break;
      case environment.CASHFLOW_TYPE_ASSERTIVE:
        let assertive = this.offerConfirmMessages?.filter((item: any) =>
          item.key.endsWith(environment.CASHFLOW_TYPE_ASSERTIVE)
        );
        this.handleBasicCashFlow(assertive);
        break;
      case environment.CASHFLOW_TYPE_DEFAULTS:
        this.isDefaults = true;
        this.handleGetCashConfig();
        break;

      default:
        return;
    }
  }

  handleBasicCashFlow(obj: any) {
    const value1Object = obj && obj[0] && JSON.parse(obj[0]?.value1);
    this.basicCashFlow.id = this.basicCashFlow?.id
      ? this.basicCashFlow.id
      : null;
    this.basicCashFlow.contact = this.basicCashFlow?.contact
      ? this.basicCashFlow.contact
      : null;
    this.basicCashFlow.months = value1Object?.months;
    this.basicCashFlow.decline = value1Object?.decline;
    this.basicCashFlow.oil = value1Object?.oil;
    this.basicCashFlow.gas = value1Object?.gas;
  }
}
