<div class="container card mt-4 pt-4 h-100">

    <app-breadcrumb-container
    [containerClass]="'breadcrumb-container'"
    [breadcrumbItems]="[
      { label: 'Home', route: '/home' },
      { label: 'MarketPlace', route: '/market-place' },
      { label: isDirectSaleOffer ? 'Direct Sale' : 'All Listings', route: isDirectSaleOffer ? '' : '/all-listing' }
    ]"
    
  ></app-breadcrumb-container>

    <div class="d-flex flex-wrap gap-5 mt-4 mb-3 align-items-center" *ngIf="!isDirectSaleOffer">

        <div class=" flex-grow-1">
            <ng-multiselect-dropdown [ngStyle]="{width:'100%',display: 'block'}" enableCheckAll="false"
                [placeholder]="'Filter By Projects'" [settings]="dropdownSettings" [data]="allActiveProjects"
                [(ngModel)]="selectedItems" (onDeSelect)="handleChange()" (onSelect)="handleChange()">
            </ng-multiselect-dropdown>
        </div>

        <div class=" flex-grow-1">
            <ng-multiselect-dropdown [ngStyle]="{width:'100%',display: 'block'}" enableCheckAll="false"
                [placeholder]="'Auction Type'" [settings]="dropdownSettings" [data]="auctionTypeFilters"
                [(ngModel)]="auctionFiltersSelected" (onDeSelect)="handleChange()" (onSelect)="handleChange()">
            </ng-multiselect-dropdown>
        </div>

        <div class=" pe-auto  d-flex flex-grow-1 justify-content-around">
            <div class="list-type-heading mb-1" (click)="toggleListing('Active')">
                <span [ngClass]="{'list-underline':offerStatus =='Active'}">Active Listings</span>
            </div>
            <div class="list-type-heading mb-1" (click)="toggleListing('Accepted')">
                <span [ngClass]="{'list-underline': offerStatus =='Accepted'}">Closed Listings</span>
            </div>
            <div class="list-type-heading" (click)="toggleListing('Cancelled')">
                <span [ngClass]="{'list-underline': offerStatus =='Cancelled'}">Cancelled Listings</span>
            </div>
        </div>

    </div>


    <div class=" table-responsive " [ngClass]="{'mt-4': isDirectSaleOffer}">
        <table class="table" matSort (matSortChange)="onSortChange($event)">
            <thead>
                <tr>
                    <th *ngFor="let item of offersColumns ; index as i " class="text-secondary"
                        [ngClass]="{'text-end': (i > 0 && i !=2), 'pl-4': i==2} " mat-sort-header="{{item}}">{{item}}
                    </th>
                </tr>
            </thead>
            <tbody class="my-listing-table-body" id="accordion">
                <ng-container *ngFor=" let offer of myOffers | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                };
            let i = index">
                    <tr class="text-end text-secondary">
                        <td class="text-start"><span class="">{{offer.projectId}}</span> <br> {{offer.accountMpName}}</td>
                        <td class="text-end">
                            <span class="mr-3">
                                {{offer.auctionEnd | date:'MM/dd/YY'}}
                            </span>
                        </td>
                        <td class="text-start pl-4"> {{offer.auctionType}}</td>
                        <td class="text-end">
                            <span class="mr-3">

                                {{offer.listedNMA}}
                            </span>
                        </td>
                        <td class="text-end">
                            <span class="mr-3">
                                {{offer.minimumAsk | currency:'':'':'.0-0'}}
                            </span>
                        </td>
                        <td class="text-end" *ngIf="offerStatus == 'Active'">
                            <span class="mr-3">

                                {{offer.activation_time | date:'MM/dd/YY h:mma'}}
                            </span>
                        </td>
                        <td class="text-end">
                            <span class="mr-3">
                                {{offer.auctionType != 'Fix Price' &&
                                !offer.auctionType.endsWith('Buy Now or Make an Offer') ?
                                "N/A" : offer.buyNowPrice | currency:'':'':'0.0'}}
                            </span>
                        </td>
                        <td class="text-end">
                            <span class="mr-3">
                                {{offer.auctionType.startsWith('Silent -') ? '' : offer.noOfBids}}
                            </span>
                        </td>
                        <td class="text-end">
                            <span class="mr-3">
                                {{offer.auctionType.startsWith('Silent -') ? 'silent' : offer.highestBid |
                                currency:'':'':'.0-0'}}
                            </span>
                        </td>
                        <ng-container *ngIf="offerStatus == 'Active'">

                            <td id="heading{{i}}">
                                <span data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="false"
                                    [attr.aria-controls]="'collapse'+i" class="data-details collapsed pe-auto mr-2"
                                    title="Offer details"
                                    [ngClass]="{'highest-offer':(offer.isHighestOffer  &&
                                        !offer.auctionType.startsWith('Silent -') && offer.offerAmount && !offer.isSameOffer) , 'not-highest-offer' :
                                        (!offer.isHighestOffer  && !offer.auctionType.startsWith('Silent -') && offer.offerAmount)  }"
                                    (click)="handleListDetails(offer.listingId , offer.offer_id)">

                                    {{!offer.offerAmount ? 'make offer':offer.offerAmount |
                                    currency:'':'':'.0-0'}}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="offerStatus == 'Accepted'">

                            <td class="text-end">
                                <span class="mr-3">
                                    {{offer.SalePrice | currency:'':'':'.0-0'}}
                                </span>
                            </td>
                            <td class="text-end">
                                <span class="mr-3">
                                    {{offer.SaleDate | date:'MM/dd/YY'}}
                                </span>
                            </td>

                        </ng-container>
                        <ng-container *ngIf="offerStatus == 'Cancelled'">
                            <td class="text-end">
                                <span class="mr-3">
                                    {{offer.Cancel_Date | date:'MM/dd/YY'}}
                                </span>
                            </td>
                        </ng-container>

                    </tr>
                    <tr id="collapse{{i}}" class=" collapse fade" [ngClass]="{'show':isDirectSaleOffer }"
                        [attr.aria-labelledby]="'heading'+i" data-parent="#accordion">
                        <td [attr.colspan]="offerStatus == 'Accepted' ? '10' : '10'">

                            <div class="card-body border shadow-lg p-4 mb-5 bg-white rounded">

                                <app-listing-details [listDetails]="listDetails" [offer]="offer">
                                </app-listing-details>
                                <hr>
                                <span *ngIf="listDetails">
                                    <app-my-offers [constraintOptions]="constraintOptions" [newOffer]="newOffer"
                                        [offer]="offer" [listDetails]="listDetails" [statusOptions]="statusOptions"
                                        (updateOffers)="handleUpdateOffers()" [index]="i">
                                    </app-my-offers>
                                </span>


                            </div>
                        </td>
                    </tr>
                </ng-container>

            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)"
            *ngIf="handleLength(myOffers)">
        </pagination-controls>
    </div>

</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
</ngx-spinner>