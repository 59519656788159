import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TransactionsModule } from '../../transactions/transaction.module';
import { BuyerTransactionsComponent } from './buyer-transactions.component';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
      path: '',
      component: BuyerTransactionsComponent
    }
  ];

@NgModule({
    declarations: [
        BuyerTransactionsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FormsModule,
        TransactionsModule
    ],
    providers: [],
    exports: [BuyerTransactionsComponent]
})
export class BuyerTransactionsModule { }
