import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BreadcrumbComponent } from './breadcrumb-container.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    BreadcrumbComponent
  ],
  imports: [
    RouterModule,
    FormsModule,
    CommonModule

  ],
  providers: [],
  exports: [BreadcrumbComponent]
})
export class BreadcrumbModule { }
