import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AddNewListingComponent } from './add-new-listing.component';
import { GeneralDataTabComponent } from './general-data-tab/general-data-tab.component';
import { ListingDetailsTabComponent } from './listing-details-tab/listing-details-tab.component';
import { AddTractsTabComponent } from './add-tracts-tab/add-tracts-tab.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { AllOffersTabComponent } from './all-offers-tab/all-offers-tab.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CashFlowCalculatorModule } from 'src/components/pages/cash-flow-calculator/cash-flow-calculator.module';
import { MaterialModule } from 'src/common/material.module';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        AddNewListingComponent,
        GeneralDataTabComponent,
        ListingDetailsTabComponent,
        AddTractsTabComponent,
        AllOffersTabComponent
    ],
    imports: [
        RouterModule,
        FormsModule,
        CommonModule,
        CurrencyMaskModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        CashFlowCalculatorModule,
        MaterialModule

    ],
    providers: [],
    exports: [AddNewListingComponent]
})
export class AddNewListingModule {

}
