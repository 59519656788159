import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, format?: string, timeZone?: string): string {
    const formattedDate = this.datePipe.transform(value, format, timeZone);
    return formattedDate || '';
  }

  getFormattedDate(value: string): string {
    
    const formattedDate = this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss', 'utc');
    const localDate = new Date(formattedDate + 'Z');
    const localFormattedDate = this.datePipe.transform(localDate, 'yyyy-MM-ddTHH:mm:ss');
    return localFormattedDate ? localFormattedDate.slice(0, 16) : '';
  }
}
