import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BreadcrumbModule } from 'src/components/breadcrumb/breadcrumb-container.module';
import { MaterialModule } from 'src/common/material.module';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ListingDetailsModule } from '../listing-details/listing-details.module';
import { MyOffersDetailsModule } from '../my-offers/my-offers.module';
import { AllActiveListingComponent } from './all-active-listings.component';


const routes: Routes = [
  {
    path: '',
    component: AllActiveListingComponent
  }
];

@NgModule({
  declarations: [
    AllActiveListingComponent
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    CommonModule,
    NgxPaginationModule,
    CurrencyMaskModule,
    NgxSpinnerModule,
    ListingDetailsModule,
    MyOffersDetailsModule,
    BreadcrumbModule,
    MaterialModule,
    RouterModule.forChild(routes)
  ],
  providers: [],
  exports: [AllActiveListingComponent,]
})
export class AllActiveListingsModule { }
