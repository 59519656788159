import { environment } from 'src/environments/environment';
import { FundsMethods } from './../../../../model/my-listings';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddNewListingService } from 'src/components/services/add-new-listing.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { LoginService } from 'src/components/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { OfferConfirmMessages } from 'src/components/model/login';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/components/services/common.service';
import { Status } from 'src/components/model/my-listings';

@Component({
  selector: 'app-eckard-transactions-methods-details',
  templateUrl: './eckard-transactions-methods-details.component.html',
  styleUrls: ['./eckard-transactions-methods-details.component.css'],
})
export class EckardTransactionsMethodsDetailsComponent implements OnInit {
  @Input() index!: number;
  @Input() transaction!: FundsMethods;
  offerConfirmMessages!: OfferConfirmMessages[];
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: '',
  };
  currentFTM!: FundsMethods;
  statusOptions!: Status[];
  @Output() handleSubmit = new EventEmitter();

  constructor(
    private myOffersService: MyOffersService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private addNewListingService: AddNewListingService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.handleOfferDealMessages();
    this.handleGetStatus();
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  handleOfferDealMessages() {
    this.myOffersService.handleOfferDealMessages().subscribe(
      (response: any) => {
        this.offerConfirmMessages = response;
      },
      (error: any) => {
        console.error('Error getting key vlaue  : ', error);
      },
      () => console.log('Done getting key vlaue .')
    );
  }

  handleGetStatus() {
    this.addNewListingService.handleGetStatus().subscribe(
      (response: Status[]) => {
        this.statusOptions = response;
      },
      (error: any) => {
        console.log('Error getting status', error);
      },
      () => console.log('Done getting status ')
    );
  }

  handleAlertMessage(obj: FundsMethods) {
    this.currentFTM = obj;
    let message = this.offerConfirmMessages?.filter(
      (item: any) => item.key == environment.APPROVE_FTM_DISCLAIMER
    );
    this.offerDisclaimer = message[0];
  }

  handleApproveFTM(method: any) {
    this.spinner.show();

    let accepted = this.statusOptions?.find(
      (item: any) => item.status === 'Accepted'
    );
    method.status = accepted?.id;
    this.loginService.updateTransactionMethod(method).subscribe(
      (response) => {
        this.spinner.hide();
        this.handleSubmit.emit();
        this.toastr.success(environment.ACCOUNT_METHODE_APPROVE_SUCCESS_MESSAGE);
      },
      (error: any) => {
        this.spinner.hide();
        console.log('Error getting  Approve Asset Transfer', error);
      },
      () => console.log('Done getting  Approve Asset Transfer ')
    );
  }
}
