<ng-container *ngIf="listDetails">
    <div>
        <h5 class="offer-heading">Listing Details</h5>
    </div>
    <div class="d-flex flex-row mt-4 ">
        <div class="col gap-2 p-0 ">
            <label for="Seller" class="form-label fw-bold">Seller: </label>
            <span class=" px-2 text-muted listing-details-content">
                <ng-container *ngIf="isTransaction || isEckardTeam; else transactionMpBlock">
                    {{offer.account.mpName}} <span *ngIf="isEckardTeam">({{offer.account.accountId}})</span>
                </ng-container>
                <ng-template #transactionMpBlock>
                    {{offer.accountMpName}}
                </ng-template>

            </span>
        </div>

        <div class="col gap-2 p-0 ">
            <label for="Project" class="form-label fw-bold">Project: </label>
            <span class=" px-2 text-muted listing-details-content">
                <ng-container *ngIf="isTransaction || isEckardTeam; else transactionProjectBlock">
                    {{offer.project.projectId}}
                </ng-container>
                <ng-template #transactionProjectBlock>
                    {{offer.projectId}}
                </ng-template>
            </span>
        </div>
        <div class="col gap-2 p-0 ">
            <ng-container *ngIf="isEckardTeam ; else isEckardElseBlockNMA">
                <label for="listednma" class="form-label fw-bold">Listed NMA:</label>
                <span class=" px-2 text-muted listing-details-content">{{listDetails.nma}}</span>

            </ng-container>
            <ng-template #isEckardElseBlockNMA>

                <label for="AuctionType" class="form-label fw-bold ">Auction Type: </label>
                <span class=" px-2 text-muted listing-details-content">

                    <ng-container *ngIf="isTransaction || isEckardTeam; else transactionAuctionBlock">
                        <ng-container
                            *ngIf="offer.auction_type.auctionLabel?.startsWith('Public -') || offer.auction_type.auctionLabel?.startsWith('Silent -') ; else elseAuctionType">
                            {{offer.auction_type.auctionLabel.slice(8)}}
                        </ng-container>
                        <ng-template #elseAuctionType>
                            {{offer.auction_type.auctionLabel}}
                        </ng-template>
                    </ng-container>
                    <ng-template #transactionAuctionBlock>
                        <ng-container
                            *ngIf="offer.auctionType?.startsWith('Public -') || offer.auctionType?.startsWith('Silent -') ; else elseAuctionType">
                            {{offer.auctionType.slice(8)}}
                        </ng-container>
                        <ng-template #elseAuctionType>
                            {{offer.auctionType}}
                        </ng-template>
                    </ng-template>

                </span>
            </ng-template>
        </div>

    </div>

    <div class="d-flex flex-row mt-3" *ngIf="!isEckardTeam">
        <div class="col gap-2 p-0 ">
            <label for="listednma" class="form-label fw-bold">Listed NMA:</label>
            <span class=" px-2 text-muted listing-details-content">{{listDetails.nma}}</span>
        </div>

        <div class="col gap-2 p-0 ">
            <label for="minimumAsk" class="form-label fw-bold">Minimum Ask: </label>
            <span class="text-muted px-2 listing-details-content">{{listDetails.minimumAsk |
                currency:'':'':'.2-2'}} ({{listDetails.minimumAsk / listDetails.nma |
                currency:'':'':'.2-2'}}/NMA)</span>
        </div>
        <div class="col gap-2 p-0 ">
            <label for="buynow" class="form-label fw-bold">Buy Now:
            </label>
            <ng-container *ngIf="isTransaction; else transactionBuyNowBlock">
                <span class=" px-2 text-muted listing-details-content"
                    *ngIf="!(offer.auction_type.auctionLabel != 'Fix Price' && !offer.auction_type.auctionLabel?.endsWith('Buy Now or Make an Offer')); else elseBuyNow">{{listDetails.buyNowPrice
                    |
                    currency:'':'':'.2-2'}} ({{listDetails.buyNowPrice / listDetails.nma |
                    currency:'':'':'.2-2'}}/NMA)</span>
                <ng-template #elseBuyNow>
                    <span class=" px-2 text-muted listing-details-content">N/A</span>
                </ng-template>
            </ng-container>

            <ng-template #transactionBuyNowBlock>
                <span class=" px-2 text-muted listing-details-content"
                    *ngIf="!(offer.auctionType != 'Fix Price' && !offer.auctionType?.endsWith('Buy Now or Make an Offer')); else elseBuyNow">{{listDetails.buyNowPrice
                    |
                    currency:'':'':'.2-2'}} ({{listDetails.buyNowPrice / listDetails.nma |
                    currency:'':'':'.2-2'}}/NMA)</span>
                <ng-template #elseBuyNow>
                    <span class=" px-2 text-muted listing-details-content">N/A</span>
                </ng-template>
            </ng-template>

        </div>

    </div>
    <div class="mt-3 d-flex flex-row text-wrap">
        <div class="col-4 gap-2 p-0 d-flex flex-row">
            <div>
                <label for="Constraints" class="form-label fw-bold">Constraints: </label>
            </div>

            <div class="p-0">
                <ng-container *ngIf="listDetails?.constraints?.length > 0; else elseBlock">
                    <ul>
                        <li *ngFor="let item of listDetails.constraints">
                            {{item.sellLabel}}

                        </li>
                    </ul>
                </ng-container>
                <ng-template #elseBlock>
                    <ul>
                        <li>
                            No constraints

                        </li>
                    </ul>
                </ng-template>

            </div>

        </div>
        <div class="col-8 p-0 gap-2 flex-row d-flex">
            <div>
                <label for="comments" class="form-label fw-bold">Comments: </label>
            </div>
            <div>
                <p class="px-4">{{listDetails.comments}} </p>
            </div>
        </div>
    </div>

</ng-container>