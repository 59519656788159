import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AddNewListingModule } from './add-new-listing/add-new-listing.module';
import { MyListingComponent } from './my-listing.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BreadcrumbModule } from 'src/components/breadcrumb/breadcrumb-container.module';
import { MaterialModule } from 'src/common/material.module';
import { CustomDatePipe } from 'src/common/custom-date.pipe';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
      path: '',
      component: MyListingComponent
    }
  ];


@NgModule({
    declarations: [
        MyListingComponent,

    ],
    imports: [
        RouterModule.forChild(routes),
        FormsModule,
        AddNewListingModule,
        CommonModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        BreadcrumbModule,
        MaterialModule

    ],
    providers: [CustomDatePipe],
    exports: [MyListingComponent]
})
export class MyListingModule { }
