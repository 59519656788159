import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationsModule } from '../notifications/notifications.module';
import { TransactionsModule } from '../transactions/transaction.module';
import { MyListingModule } from './my-listing/my-listing.module';
import { SellerNotificationsModule } from './seller-notifications/seller-notifications.module';
import { SellerTransactionsModule } from './seller-transactions/seller-transactions.module';
import { CustomDatePipe } from 'src/common/custom-date.pipe';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
    ],
    imports: [
        RouterModule,
        FormsModule,
        MyListingModule,
        SellerNotificationsModule,
        SellerTransactionsModule,
        BrowserAnimationsModule,
        TransactionsModule,
        NotificationsModule
    ],
    providers: [CustomDatePipe],
    exports: []
})
export class SellerModule { }
