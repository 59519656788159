import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProfileComponent } from './profile.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SignUpModule } from 'src/components/auth/sign-up/sign-up.module';
import { CapitalAmountComponent } from './capital-amount/capital-amount.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BreadcrumbModule } from 'src/components/breadcrumb/breadcrumb-container.module';
import { Routes, RouterModule } from '@angular/router';
import { ResetPasswordModule } from 'src/components/auth/reset-password/reset-password.module';


const routes: Routes = [
    {
      path: '',
      component: ProfileComponent
    }
  ];

@NgModule({
  declarations: [ProfileComponent, CapitalAmountComponent],
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    ResetPasswordModule,
    CurrencyMaskModule,
    BreadcrumbModule
  ],
  providers: [],
  exports: [ProfileComponent],
})
export class ProfileModule {}
