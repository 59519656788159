import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EckardTransactionsMethodsComponent } from './eckard-transactions-methods.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { EckardTransactionsMethodsDetailsComponent } from './eckard-transactions-methods-details/eckard-transactions-methods-details.component';
import { BreadcrumbModule } from 'src/components/breadcrumb/breadcrumb-container.module';
import { MaterialModule } from 'src/common/material.module';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: EckardTransactionsMethodsComponent,
  }
];

@NgModule({
  declarations: [EckardTransactionsMethodsComponent, EckardTransactionsMethodsDetailsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    BreadcrumbModule,
    MaterialModule
  ],
  providers: [],
  exports: [EckardTransactionsMethodsComponent]
})
export class EckardTransactionsMethodsModule { }
