<div class="container card mt-4 pt-4 h-100">
  <div class="tab-content" id="myTabContent">
    <app-breadcrumb-container
      [isActiveUser]="!isActiveUser"
      [containerClass]="'breadcrumb-container mb-4'"
      [breadcrumbItems]="[
        { label: 'Home', route: '/home' },
        { label: 'MarketPlace', route: '/market-place' }
      ]"
    ></app-breadcrumb-container>
    <div
      class="tab-pane fade show active mt-4 p-4"
      id="marketPlace"
      role="tabpanel"
      aria-labelledby="home-tab"
    >
      <ng-container *ngIf="isEckard; else elseEckardBlock">
        <div class="row">
          <div class="col">
            <h5>Buyer</h5>
          </div>
          <div class="col">
            <h5>Seller</h5>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div
              class="mb-3 card d-flex shadow-sm p-3 mb-2 bg-white pe-auto rounded justify-content-between flex-row p-2 align-items-center"
              [ngClass]="{ isDisabled: !isActiveUser }"
              [routerLink]="['/all-listing']"
            >
              <div>
                <img
                  src="assets/tag.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <a class="marketplace-items">All Listings </a>({{
                  allActiveListings
                }})
              </div>
              <div>
                <img
                  src="assets/chevron-right.svg"
                  width="20"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
          <div class="col"></div>
        </div>

        <div class="row">
          <div class="col">
            <div
              class="mb-3 card d-flex shadow-sm p-3 mb-2 pe-auto bg-white rounded justify-content-between flex-row p-2 align-items-center"
              [ngClass]="{ isDisabled: !isActiveUser }"
              [routerLink]="['/my-offers']"
            >
              <div>
                <img
                  src="assets/edit.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <a class="marketplace-items">My Offers </a>({{ totalMyOffers }})
              </div>
              <div>
                <img
                  src="assets/chevron-right.svg"
                  width="20"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="mb-3 card d-flex shadow-sm p-3 mb-2 bg-white pe-auto rounded justify-content-between flex-row p-2 align-items-center"
              [ngClass]="{ isDisabled: !isActiveUser }"
              [routerLink]="['/my-listing']"
            >
              <div>
                <img
                  src="assets/tag.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <a class="marketplace-items">My Listings </a>({{
                  totalMyListings
                }})
              </div>
              <div>
                <img
                  src="assets/chevron-right.svg"
                  width="20"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div
              class="mb-3 card d-flex shadow-sm p-3 pe-auto mb-2 bg-white rounded justify-content-between flex-row p-2 align-items-center"
              [routerLink]="['/buyer-pending-transactions']"
            >
              <div>
                <img
                  src="assets/file-minus.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <a class="marketplace-items">Pending Transactions </a>({{
                  buyerTotalPendingList
                }})
              </div>
              <div>
                <img
                  src="assets/chevron-right.svg"
                  width="20"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="mb-3 card d-flex shadow-sm p-3 pe-auto mb-2 bg-white rounded justify-content-between flex-row p-2 align-items-center"
              [routerLink]="['/seller-pending-transactions']"
            >
              <div>
                <img
                  src="assets/file-minus.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <a class="marketplace-items">Pending Transactions </a>({{
                  sellerTotalPendingList
                }})
              </div>
              <div>
                <img
                  src="assets/chevron-right.svg"
                  width="20"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div
              class="mb-3 card d-flex shadow-sm p-3 pe-auto mb-2 bg-white rounded justify-content-between flex-row p-2 align-items-center"
              [routerLink]="['/buyer-closed-transactions']"
            >
              <div>
                <img
                  src="assets/file-minus.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <a class="marketplace-items">Closed Transactions </a>({{
                  buyerTotalClosedList
                }})
              </div>
              <div>
                <img
                  src="assets/chevron-right.svg"
                  width="20"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="mb-3 card d-flex shadow-sm p-3 pe-auto mb-2 bg-white rounded justify-content-between flex-row p-2 align-items-center"
              [routerLink]="['/seller-closed-transactions']"
            >
              <div>
                <img
                  src="assets/file-minus.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <a class="marketplace-items">Closed Transactions </a>({{
                  sellerTotalClosedList
                }})
              </div>
              <div>
                <img
                  src="assets/chevron-right.svg"
                  width="20"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div
              [routerLink]="['/buyer-notifications']"
              class="mb-3 card d-flex shadow-sm p-3 pe-auto mb-5 bg-white rounded justify-content-between flex-row p-2 align-items-center"
            >
              <div>
                <img
                  src="assets/bell.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <a class="marketplace-items">Notifications </a>({{
                  buyerNotificationsCount
                }})
              </div>
              <div>
                <img
                  src="assets/chevron-right.svg"
                  width="20"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div
              [routerLink]="['/seller-notifications']"
              class="mb-3 card d-flex shadow-sm p-3 pe-auto mb-5 bg-white rounded justify-content-between flex-row p-2 align-items-center"
            >
              <div>
                <img
                  src="assets/bell.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <a class="marketplace-items">Notifications </a>({{
                  sellerNotificationsCount
                }})
              </div>
              <div>
                <img
                  src="assets/chevron-right.svg"
                  width="20"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4 row">
          <div class="">
            <div class="card gap-3 shadow-sm p-3 mb-2 bg-white rounded p-2">
              <div>
                <img
                  src="assets/info.svg"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="chevron-right"
                />
                <span class="ml-1">Important Information</span>
              </div>
              <div class="pl-4 pe-auto">
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  (click)="handleMessage('SEC Disclosure')"
                >
                  <a class="marketplace-items marketplace-items-disclosure"
                    >SEC Disclosure
                  </a>
                  <img
                    src="assets/external-link.svg"
                    width="24"
                    height="24"
                    class="m-1"
                    alt="chevron-right"
                  />
                </span>

                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  (click)="handleMessage('User Agreement')"
                >
                  <a class="marketplace-items marketplace-items-disclosure"
                    >User Agreement </a
                  ><img
                    src="assets/external-link.svg"
                    width="24"
                    height="24"
                    class="m-1"
                    alt="chevron-right"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #elseEckardBlock>
        <app-eckard-staff-place></app-eckard-staff-place>
      </ng-template>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>

<div
  class="modal fade text-wrap"
  id="exampleModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5
          class="modal-title"
          id="staticBackdropLabel"
          [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
        ></h5>
      </div>
      <div class="modal-body" [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"></div>
      <div class="modal-footer">
        <div class="mr-auto">
          <button
            type="button"
            class="btn btn-warning text-white mr-2 fw-bold"
            data-bs-dismiss="modal"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
