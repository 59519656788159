import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Account,
  AuctionType,
  ListingType,
  Project,
  Status,
  Tract,
} from 'src/components/model/my-listings';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/components/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AddNewListingService implements OnInit {
  projectsOptions: Array<string> = [
    'ANY Project',
    'ELA Bryant',
    'ELA Cooley',
    'ELA Mobley',
    '221203 AUE',
  ];

  selectAllOperators!: boolean;
  selectAllCountries!: boolean;
  selectAllProject!: boolean;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    console.log('add new listings service');
  }

  toggleSelection(flag: boolean, value: any) {
    switch (value) {
      case 'selectAllOperators':
        this.selectAllOperators = flag;
        break;
      case 'selectAllCountries':
        this.selectAllCountries = flag;
        break;
      case 'selectAllProject':
        this.selectAllProject = flag;
        break;
      default:
        return;
    }
  }

  getSelectedValues() {}

  handleRemoveAndAddClass() {
    this.toggleActiveClass(['listingDetails-tab', 'listingDetails'], false);
    this.toggleActiveClass(['addTracts-tab', 'addTracts'], true);
  }

  handleGetListType(): Observable<ListingType[]> {
    const res = this.apiService
      .request(`${environment.API_BASE_URL}/listing_type/`, 'GET')
      .pipe(tap((response: any) => response.body));
    return res;
  }

  handleAuctionType(): Observable<AuctionType[]> {
    const res = this.apiService
      .request(`${environment.API_BASE_URL}/auction_type/`, 'GET')
      .pipe(tap((response: any) => response.body));
    return res;
  }

  handleConstraint(): Observable<any[]> {
    const res = this.apiService
      .request(`${environment.API_BASE_URL}/constraint`, 'GET')
      .pipe(tap((response: any) => response.body));
    return res;
  }

  handleGetAllProjects(): Observable<Project[]> {
    const res = this.apiService
      .request(`${environment.API_BASE_URL}/project/`, 'GET')
      .pipe(tap((response: any) => response.body));
    return res;
  }

  handleGetAllAccounts(): Observable<Account[]> {
    const res = this.apiService
      .request(`${environment.API_BASE_URL}/account/`, 'GET')
      .pipe(tap((response: any) => response.body));
    return res;
  }

  handleGetStatus(): Observable<Status[]> {
    
    const res = this.apiService
      .request(`${environment.API_BASE_URL}/status/`,'GET')
      .pipe(tap((response: any) => response.body));
    return res;
  }

  handleGetTracts(): Observable<Tract[]> {
  
    const res = this.apiService
      .request(`${environment.API_BASE_URL}/tract/`,'GET')
      .pipe(tap((response: any) => response.body));
    return res;
  }

  private toggleActiveClass(elements: string[], isAdd: boolean) {
    elements.forEach((element) => {
      let currentElement = document.getElementById(element);
      if (isAdd) {
        currentElement?.classList.add('active');
        currentElement?.classList.add('show');
      } else {
        currentElement?.classList.remove('active');
        currentElement?.classList.remove('show');
      }
    });
  }
}
