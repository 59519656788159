import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BuyerNotificationsModule } from './buyer-notifications/buyer-notifications.module';
import { BuyerTransactionsModule } from './buyer-transactions/buyer.transactions.module';
import { MyBidsModule } from './my-bids/my-bids.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TransactionsModule } from '../transactions/transaction.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
    ],
    imports: [
      NgMultiSelectDropDownModule.forRoot(),
        FormsModule,
        BuyerNotificationsModule,
        BuyerTransactionsModule,
        MyBidsModule,
        BrowserAnimationsModule,
        TransactionsModule,
        NotificationsModule,
        RouterModule
    ],
    providers: [],
    exports: []
})
export class BuyerModule { }
