<div class="container card mt-4 pt-4 h-100">
  <app-breadcrumb-container
    [containerClass]="'breadcrumb-container mb-3'"
    [breadcrumbItems]="[
      { label: 'Home', route: '/home' },
      { label: 'MarketPlace', route: '/market-place' },
      { label: 'Profile', route: '/profile' }
    ]"
  ></app-breadcrumb-container>
  <div class="container rounded bg-white mb-3">
    <div class="row">
      <div class="col p-0">
        <div class="py-2">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h4 class="text-right">Profile Settings</h4>
          </div>
          <div class="row">
            <div class="col-md-6 mt-2 text-start">
              <label class="labels">First Name</label
              ><input
                type="text"
                class="form-control"
                id="firstName"
                name="firstName"
                [(ngModel)]="profile.firstName"
                #firstName="ngModel"
                required
                [ngClass]="{
                  'border border-danger':
                    firstName.invalid && (firstName.dirty || firstName.touched)
                }"
              />
              <div
                *ngIf="
                  firstName.invalid &&
                  (firstName.dirty || firstName.touched) &&
                  firstName.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please enter First Name
              </div>
            </div>

            <div class="col-md-6 mt-2 text-start">
              <label class="labels">Last Name</label
              ><input
                type="text"
                class="form-control"
                id="lastName"
                name="lastName"
                [(ngModel)]="profile.lastName"
                #lastName="ngModel"
                required
                [ngClass]="{
                  'border border-danger':
                    lastName.invalid && (lastName.dirty || lastName.touched)
                }"
              />
              <div
                *ngIf="
                  lastName.invalid &&
                  (lastName.dirty || lastName.touched) &&
                  lastName.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please enter Last Name
              </div>
            </div>

            <div class="col-md-6 mt-2 text-start">
              <label class="labels">Email</label
              ><input
                type="email"
                class="form-control"
                [(ngModel)]="profile.email"
                required
                #email="ngModel"
                id="email"
                name="email"
                [ngClass]="{
                  'border border-danger':
                    email.invalid && (email.dirty || email.touched)
                }"
                email
                disabled
              />
              <div
                *ngIf="
                  email.invalid &&
                  (email.dirty || email.touched) &&
                  email.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please enter valid email
              </div>
            </div>

            <div class="col-md-6 text-start mt-2">
              <label class="labels">Account Status</label
              ><input
                type="text"
                class="form-control"
                [ngClass]="{
                  'text-success': profile.mpStatus == 'active',
                  'text-danger': profile.mpStatus != 'active'
                }"
                id="mpStatus"
                name="mpStatus"
                [(ngModel)]="profile.mpStatus"
                disabled
              />
            </div>
            <div class="col-md-12 text-start mt-3">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="emailNotificationsCheckbox"
                  [(ngModel)]="profile.notification"
                />
                <label class="form-check-label" for="emailNotificationsCheckbox"
                  >Send email notifications</label
                >
              </div>
            </div>
            <div class="row mt-5 align-items-center mb-4">
              <div
                class="col-md-3 col-sm-6 text-start fw-bold"
                style="font-size: medium"
              >
                <button
                  class="btn btn-primary profile-button"
                  type="button"
                  (click)="handleSubmit()"
                  [disabled]="
                    !profile.firstName ||
                    !profile.lastName ||
                    !profile.email ||
                    email.invalid
                  "
                >
                  Save Profile
                </button>
              </div>

              <div
                class="col-md-3 col-sm-6 text-start pe-auto"
                style="text-decoration: underline"
                data-toggle="modal"
                data-target="#exampleModalLong"
                title="change password"
                (click)="handleChangePasswor()"
              >
                Change Password
              </div>
              <div
                class="col-md-3 col-sm-6 text-start pe-auto"
                style="text-decoration: underline"
                data-toggle="modal"
                data-target="#exampleModalLong1"
                title="add capital amount"
                *ngIf="handleIsEckardUser()"
                (click)="capitalAmount.addFunds = 0"
              >
                Add Capital Amount
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="handleIsEckardUser()">
      <div class="row mt-2 p-0 mb-3" *ngIf="handleUserHandles()">
        <div class="text-start fw-bold" style="font-size: medium">
          User Handles
        </div>
        <div class="row px-3 mt-3">
          <div>Buyer: {{ userInfo[0].account.contact.mpName || " " }}</div>
          <div *ngFor="let item of userInfo">
            Seller '{{ item?.account?.accountName }}':
            {{ item?.account?.mpName }}
          </div>
        </div>
      </div>

      <div class="row mt-2 p-0">
        <div class="col-md-3 text-start fw-bold" style="font-size: medium">
          Fund Transfer Methods
        </div>

        <div
          class="col text-start pe-auto"
          (click)="clickAddAccount()"
          style="text-decoration: underline"
        >
          Add New Transfer Method
        </div>
      </div>

      <div *ngIf="addAccount">
        <div class="row mt-2">
          <div class="col-md-6">
            <label for="account" class="form-label"
              >Account <span class="text-danger">*</span></label
            >
            <select
              id="account"
              class="form-select"
              [(ngModel)]="selectAccount"
              (ngModelChange)="handleChange('account')"
              #account="ngModel"
              [ngClass]="{
                'border border-danger':
                  account.invalid && (account.dirty || account.touched)
              }"
              required
            >
              <option *ngFor="let item of accountsOptions" [value]="item.id">
                {{ item.accountName }}
              </option>
            </select>

            <span
              *ngIf="
                account.invalid &&
                (account.dirty || account.touched) &&
                account.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please select valid account
            </span>
          </div>
          <div class="col-md-6">
            <label for="type" class="form-label"
              >Transfer Type <span class="text-danger">*</span></label
            >
            <select
              id="type"
              class="form-select"
              [(ngModel)]="selectedType"
              (ngModelChange)="handleChange('type')"
              #type="ngModel"
              [ngClass]="{
                'border border-danger':
                  type.invalid && (type.dirty || type.touched)
              }"
              required
            >
              <option *ngFor="let item of accountsTypes" [value]="item">
                {{ item }}
              </option>
            </select>

            <span
              *ngIf="
                type.invalid && (type.dirty || type.touched) && type.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please select valid Type
            </span>
          </div>
        </div>

        <div class="row" *ngIf="selectedType === 'Wire'">
          <div class="col-md-6 mt-2 text-start">
            <label class="labels">Account Holder </label
            ><input
              type="text"
              class="form-control"
              id="recipient"
              name="recipient"
              [(ngModel)]="methods.Recipient"
              #recipient="ngModel"
              required
              [ngClass]="{
                'border border-danger':
                  recipient.invalid && (recipient.dirty || recipient.touched)
              }"
            />
            <div
              *ngIf="
                recipient.invalid &&
                (recipient.dirty || recipient.touched) &&
                recipient.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please enter Recipient
            </div>
          </div>

          <div class="col-md-6 mt-2 text-start">
            <label class="labels">Bank Name</label
            ><input
              type="text"
              class="form-control"
              id="bankName"
              name="bankName"
              [(ngModel)]="methods.Bank_Name"
              #bankName="ngModel"
              required
              [ngClass]="{
                'border border-danger':
                  bankName.invalid && (bankName.dirty || bankName.touched)
              }"
            />
            <div
              *ngIf="
                bankName.invalid &&
                (bankName.dirty || bankName.touched) &&
                bankName.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please enter Bank Name
            </div>
          </div>

          <div class="col-md-6 mt-2 text-start">
            <label class="labels">Account Number </label
            ><input
              type="text"
              class="form-control"
              id="accountNumber"
              name="accountNumber"
              [(ngModel)]="methods.Account_Number"
              #accountNumber="ngModel"
              required
              [ngClass]="{
                'border border-danger':
                  accountNumber.invalid &&
                  (accountNumber.dirty || accountNumber.touched)
              }"
            />
            <div
              *ngIf="
                accountNumber.invalid &&
                (accountNumber.dirty || accountNumber.touched) &&
                accountNumber.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please enter Account Number
            </div>
          </div>

          <div class="col-md-6 mt-2 text-start">
            <label class="labels">ABA Routing Number</label
            ><input
              type="text"
              class="form-control"
              id="routingNumber"
              name="routingNumber"
              [(ngModel)]="methods.Routing_Number"
              #routingNumber="ngModel"
              required
              [ngClass]="{
                'border border-danger':
                  routingNumber.invalid &&
                  (routingNumber.dirty || routingNumber.touched)
              }"
            />
            <div
              *ngIf="
                routingNumber.invalid &&
                (routingNumber.dirty || routingNumber.touched) &&
                routingNumber.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please enter ABA Routing Number
            </div>
          </div>
        </div>

        <div class="row" *ngIf="selectedType === 'Check'">
          <div class="row">
            <div class="col-md-6 mt-2 text-start">
              <label class="labels">Account Holder </label
              ><input
                type="text"
                class="form-control"
                id="recipient"
                name="recipient"
                [(ngModel)]="methods.Recipient"
                #recipient="ngModel"
                required
                [ngClass]="{
                  'border border-danger':
                    recipient.invalid && (recipient.dirty || recipient.touched)
                }"
              />
              <div
                *ngIf="
                  recipient.invalid &&
                  (recipient.dirty || recipient.touched) &&
                  recipient.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please enter Recipient
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-2 text-start">
            <label class="labels">Mail To </label
            ><input
              type="text"
              class="form-control"
              id="mailto"
              name="mailto"
              [(ngModel)]="methods.mailto"
              #mailto="ngModel"
              required
              [ngClass]="{
                'border border-danger':
                  mailto.invalid && (mailto.dirty || mailto.touched)
              }"
            />
            <div
              *ngIf="
                mailto.invalid &&
                (mailto.dirty || mailto.touched) &&
                mailto.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please enter mail to
            </div>
          </div>
          <div class="col-md-6 mt-2 text-start">
            <label class="labels">Street </label
            ><input
              type="text"
              class="form-control"
              id="streat"
              name="streat"
              [(ngModel)]="methods.Street"
              #streat="ngModel"
              required
              [ngClass]="{
                'border border-danger':
                  streat.invalid && (streat.dirty || streat.touched)
              }"
            />
            <div
              *ngIf="
                streat.invalid &&
                (streat.dirty || streat.touched) &&
                streat.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please enter streat
            </div>
          </div>

          <div class="col-md-6 mt-2 text-start">
            <label class="labels">City</label
            ><input
              type="text"
              class="form-control"
              id="city"
              name="city"
              [(ngModel)]="methods.City"
              #city="ngModel"
              required
              [ngClass]="{
                'border border-danger':
                  city.invalid && (city.dirty || city.touched)
              }"
            />
            <div
              *ngIf="
                city.invalid && (city.dirty || city.touched) && city.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please enter City
            </div>
          </div>

          <div class="col-md-6 mt-2 text-start">
            <label class="labels">State</label
            ><input
              type="text"
              class="form-control"
              id="streat"
              name="streat"
              [(ngModel)]="methods.State"
              #streat="ngModel"
              required
              [ngClass]="{
                'border border-danger':
                  streat.invalid && (streat.dirty || streat.touched)
              }"
            />
            <div
              *ngIf="
                streat.invalid &&
                (streat.dirty || streat.touched) &&
                streat.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please enter state
            </div>
          </div>

          <div class="col-md-6 mt-2 text-start">
            <label class="labels">Zip </label
            ><input
              type="text"
              class="form-control"
              id="zip"
              name="zip"
              [(ngModel)]="methods.Zip"
              #zip="ngModel"
              required
              [ngClass]="{
                'border border-danger':
                  zip.invalid && (zip.dirty || zip.touched)
              }"
            />
            <div
              *ngIf="zip.invalid && (zip.dirty || zip.touched) && zip.errors"
              class="text-danger rounded px-4 error-message"
            >
              Please enter zip
            </div>
          </div>
          <div class="col-md-6 mt-2 text-start">
            <label class="labels">Country </label>

            <select
              id="country"
              class="form-select"
              [(ngModel)]="methods.country"
              (ngModelChange)="handleCountryChange($event)"
              #country="ngModel"
              [ngClass]="{
                'border border-danger':
                  country.invalid && (country.dirty || country.touched)
              }"
              required
            >
              <option *ngFor="let item of countryOptions" [ngValue]="item">
                {{ item }}
              </option>
            </select>

            <div
              *ngIf="
                country.invalid &&
                (country.dirty || country.touched) &&
                country.errors
              "
              class="text-danger rounded px-4 error-message"
            >
              Please enter country
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-4 d-flex gap-4">
          <div class="form-check p-0">
            <button
              class="btn btn-primary profile-button"
              type="button"
              [attr.data-bs-target]="'#exampleModal'"
              data-bs-toggle="modal"
              [disabled]="
                !(
                  addAccount &&
                  selectAccount &&
                  selectedType &&
                  handleFieldsAvailable()
                )
              "
              (click)="handleAlertMessage('Add')"
            >
              Add Transfer Method
            </button>
          </div>
          <div>
            <button
              type="submit"
              class="btn btn-outline-dark cancel-button mb-2 fw-bold"
              (click)="clickAddAccount()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <ng-container>
        <div
          class="modal fade text-wrap"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title"
                  id="staticBackdropLabel"
                  [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
                ></h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="modal-body"
                [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"
              ></div>
              <div class="modal-footer">
                <div class="mr-auto">
                  <button
                    type="button"
                    class="btn btn-warning text-white mr-2 fw-bold"
                    data-bs-dismiss="modal"
                    (click)="
                      deleteFTM ? handleDeleteFTM() : handleAccountSubmit()
                    "
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger ml-2 fw-bold"
                    data-bs-dismiss="modal"
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngFor="let k of objectKeys(accountsMethods)" class="mt-3">
        {{ k }}
        <!-- {{ k }} ({{ accountsMethods[k].length }}) -->
        <div
          style="
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: lightgray;
          "
        >
          <table class="table table-borderless">
            <ng-container
              *ngFor="let list of accountsMethods[k]; let i = index"
            >
              <thead *ngIf="i == 0">
                <tr>
                  <th
                    class="text-secondary"
                    *ngFor="let key of objectKeys(list.json_fields)"
                    [ngClass]="{
                      'w-20':
                        key == 'Eckard Account' || key == 'Account Holder',
                      'w-47': key == 'Mail To'
                    }"
                  >
                    {{ key }}
                  </th>
                  <th class="text-secondary text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-secondary"
                    *ngFor="let key of objectKeys(list.json_fields)"
                  >
                    <ng-container
                      *ngIf="
                        key === 'Account Number' || key === 'ABA Routing Number'
                      "
                    >
                      <ng-container
                        *ngIf="
                          (showData[i] && key === 'Account Number') ||
                          (showData1[i] && key === 'ABA Routing Number')
                        "
                      >
                        {{ list.json_fields[key] }}
                      </ng-container>
                      <ng-container
                        *ngIf="
                          (!showData[i] && key === 'Account Number') ||
                          (!showData1[i] && key === 'ABA Routing Number')
                        "
                      >
                        ..{{ list.json_fields[key].toString().slice(-4) }}
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        key !== 'Account Number' && key !== 'ABA Routing Number'
                      "
                    >
                      {{ list.json_fields[key] }}
                      <ng-container *ngIf="key === 'Eckard Account'">
                        <ng-container *ngIf="handleFTMAccepted(list)">
                          <img
                            src="assets/eckard-approve.svg"
                            class="mx-1 pb-1 eckard-ftm-approve"
                            alt="ftm-approve"
                          />
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="key === 'Account Number'">
                      <i
                        class="fa fa-email px-4"
                        [ngClass]="showData[i] ? 'fa-eye-slash' : 'fa-eye'"
                        (click)="toggleData(i)"
                      ></i>
                    </ng-container>
                    <ng-container *ngIf="key === 'ABA Routing Number'">
                      <i
                        class="fa fa-email px-4"
                        [ngClass]="showData1[i] ? 'fa-eye-slash' : 'fa-eye'"
                        (click)="toggleData1(i)"
                      ></i>
                    </ng-container>
                  </td>
                  <td class="text-center">
                    <i
                      [attr.data-bs-target]="'#exampleModal'"
                      data-bs-toggle="modal"
                      (click)="handleAlertMessage('Delete', list)"
                      class="fa fa-trash text-danger pe-auto"
                      title="Delete FTM"
                      aria-hidden="true"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade text-wrap"
  id="exampleModal1"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  [ngClass]="{ show: isShow == true }"
  [style.display]="isShow == true ? 'block' : 'none'"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5
          class="modal-title"
          id="staticBackdropLabel"
          [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
        ></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="isShow = false"
        ></button>
      </div>
      <div
        class="modal-body"
        [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"
      ></div>
      <div class="modal-footer">
        <div class="mr-auto">
          <button
            type="button"
            class="btn btn-warning text-white mr-2 fw-bold"
            data-bs-dismiss="modal"
            (click)="handleDeleteFTM(true)"
          >
            YES
          </button>
          <button
            type="button"
            class="btn btn-danger ml-2 fw-bold"
            data-bs-dismiss="modal"
            (click)="isShow = false"
          >
            NO
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
<!-- Modal -->
<div
  class="modal fade text-wrap"
  id="exampleModalLong"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body mb-5">
        <app-reset-password
          [resetPassword]="resetPassword"
          [isChangePassword]="true"
        ></app-reset-password>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade text-wrap"
  id="exampleModalLong1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLong1Title"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body mb-5">
        <app-capital-amount
          [capitalAmount]="capitalAmount"
        ></app-capital-amount>
      </div>
    </div>
  </div>
</div>
