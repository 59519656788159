import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ListingDetailsComponent } from './listing-details.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        ListingDetailsComponent,
    ],
    imports: [
        NgMultiSelectDropDownModule.forRoot(),
        FormsModule,
        CommonModule,
        NgxPaginationModule,
        CurrencyMaskModule,
        NgxSpinnerModule,
        RouterModule

    ],
    providers: [],
    exports: [ListingDetailsComponent]
})
export class ListingDetailsModule { }
