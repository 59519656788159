import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  ListingType,
  AuctionType,
  Account,
  Status,
  Tract,
  MyListing,
  Constraint,
} from 'src/components/model/my-listings';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { AddNewListingService } from '../../../../services/add-new-listing.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/components/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { OfferConfirmMessages } from 'src/components/model/login';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/components/services/common.service';
import { environment } from 'src/environments/environment';
import { CustomDatePipe } from 'src/common/custom-date.pipe';


@Component({
  selector: 'app-add-new-listing',
  templateUrl: './add-new-listing.component.html',
  styleUrls: ['./add-new-listing.component.scss'],
})
export class AddNewListingComponent implements OnInit {
  @Output() onGoBack = new EventEmitter();

  listingId: string = '';
  errorMessages!: string;
  listingTypeOptions!: ListingType[];
  auctionTypeOptions!: AuctionType[];
  constraintOptions!: Constraint[];
  accountsOptions!: Account[];
  statusOptions!: Status[];
  tracts!: Tract[];
  isListEdit!: boolean;
  isListDraft!: boolean;
  showOffers!: boolean;
  offerConfirmMessages: OfferConfirmMessages[] = [];

  createNewListing: MyListing = {
    listing_type: null,
    status: null,
    listingName: '',
    listingStart: '',
    auction_type: null,
    auctionEnd: '',
    comments: '',
    account: null,
    project: null,
    nma: null,
    minimumAsk: null,
    buyNowPrice: null,
    constraints: [],
    listConstraints: [],
    offer: [],
    directSaleToken: '',
    is_cancel_allowed: false,
  };
  isValidNma!: boolean;
  listMessage: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: '',
  };

  minAuctionDuration!: string;
  maxAuctionDuration!: string;
  constructor(
    private addNewListingService: AddNewListingService,
    private myListingsService: MyListingsService,
    private router: Router,
    private toastr: ToastrService,
    private myOffersService: MyOffersService,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private customDatePipe: CustomDatePipe  ) {
    this.createNewListing = this.myListingsService.newListing;
    this.isListEdit = this.myListingsService.isListEdit;
    this.showOffers = this.myListingsService.showOffers;
    this.isListDraft = this.myListingsService.isListDraft;
    this.listingId = this.createNewListing.listingName;
  }

  ngOnInit(): void {
    this.handleOfferDealMessages();
    this.handleGetUserAccounts();
    this.handleGetListType();
    this.handleAuctionType();
    this.handleConstraint();
    this.handleGetStatus();
    this.handleGetTracts();
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  handleGoBack() {
    this.onGoBack.emit();
    this.myListingsService.handleResetSetNewList();
  }

  handleProjectType(value: number) {
    this.createNewListing.listing_type = value;
    if (value == 2) {
      this.toastr.info(environment.INPROGRESS_MESSAGE);
    }
  }

  handleValidNMA(event: any) {
    this.isValidNma = event;
    return this.isValidNma;
  }

  isValid(obj: any) {
    const requiredFields = [
      'listing_type',
      'listingName',
      'listingStart',
      'auction_type',
      'auctionEnd',
      'account',
      'project',
      'nma',
      'minimumAsk',
    ];
    if (
      this.createNewListing?.auction_type?.auctionType?.endsWith(environment.AUCTION_TYPE_BUY_OR_OFFER)
    ) {
      requiredFields.push('buyNowPrice');
    }
    const isValid = requiredFields.every((field) => obj[field]);

    return isValid;
  }

  isValidDrafts(obj: any) {
    const requiredFields = ['listingName', 'account', 'project'];
    let flag = requiredFields.every((field) => obj[field]);

    return flag;
  }

  handleMessage(event: any) {
    let message;
    switch (event.target.value) {
      case 'SAVE DRAFT':
        message = this.offerConfirmMessages?.filter(
          (item: any) => item.key == environment.DRAFT_DISCLAIMER
        );
        this.listMessage = message[0];
        break;
      case 'PUBLISH LISTING':
        message = this.offerConfirmMessages?.filter(
          (item: any) => item.key == environment.LISTING_DISCLAIMER
        );
        this.listMessage = message[0];
        break;
      case 'UPDATE LISTING':
        message = this.offerConfirmMessages?.filter(
          (item: any) => item.key == environment.LISTING_DISCLAIMER
        );
        this.listMessage = message[0];

        break;
      case 'CANCEL LISTING':
        message = this.offerConfirmMessages?.filter(
          (item: any) => item.key == environment.LISTING_CANCEL_DISCLAIMER
        );
        this.listMessage = message[0];
        break;

      default:
        return;
    }
  }
  handleStatus(status: any) {
    this.spinner.show();
    this.createNewListing.status = status?.id;
    this.createNewListing.project = this.createNewListing?.project?.id;
    delete this.createNewListing.directSaleToken;
    this.createNewListing.offer = this.createNewListing?.offer?.map((x: any) =>
      parseInt(x.id)
    );
    this.createNewListing.buyNowPrice =
      this.createNewListing?.auction_type?.auctionType?.endsWith(environment.AUCTION_TYPE_BUY_OR_OFFER)
        ? this.createNewListing?.buyNowPrice
        : this.createNewListing?.minimumAsk;

    if (this.isListEdit) {
      if (status.statusLabel == 'Cancelled') {
        this.myListingsService.getMyList(this.createNewListing.id).subscribe(
          (response: any) => {
            let listActive =
              response?.status?.statusLabel === 'Active' ||
              response?.status?.statusLabel === 'Draft';
            let activeOffer = response?.offer?.filter(
              (item: any) => item?.status?.status != 'Cancelled'
            );
            if (listActive && activeOffer.length == 0) {
              this.updateListing(
                this.createNewListing,
                status.statusLabel == 'Cancelled'
              );
            } else {
              this.spinner.hide();
              this.router.navigate(['/my-listing']);
              this.handleGoBack();
              this.myListingsService.handleResetSetNewList();
              this.toastr.warning(environment.UNABLE_TO_CANCEL_LIST_WARNING);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.updateListing(this.createNewListing);
      }
    } else {
      this.myListingsService.createNewListing(this.createNewListing).subscribe(
        (response) => {
          this.spinner.hide();
          this.router.navigate(['/my-listing']);
          this.handleGoBack();
          this.myListingsService.handleResetSetNewList();
          this.toastr.success(environment.LIST_CREATE_SUCCESS_MESSAGE);
        },
        (error: any) => {
          this.spinner.hide();

          Object.keys(error.error).map((key) => {
            this.toastr.error(error.error[key][0], key, {
              progressBar: false,
              tapToDismiss: false,
              disableTimeOut: true,
            });
          });
          console.log('error create new list', this.errorMessages);
        },
        () => console.log('Done create new List')
      );
    }
  }

  handleGetListType() {
    this.addNewListingService.handleGetListType().subscribe(
      (response) => {
        this.listingTypeOptions = response;
      },
      (error: any) => {
        console.log('error', error);
      },
      () => console.log('Done getting List Type')
    );
  }

  handleAuctionType() {
    this.addNewListingService.handleAuctionType().subscribe(
      (response) => {
        this.auctionTypeOptions = response;
      },
      (error: any) => {
        console.log('Error getting listing Auction Type', error);
      },
      () => console.log('Done getting listing Auction Type')
    );
  }

  handleConstraint() {
    this.addNewListingService.handleConstraint().subscribe(
      (response: any) => {
        const sellOptions: any = [];
        response?.map((item: any) => {
          if (item.sellLabel) {
            if (this.createNewListing?.constraints?.includes(item.id)) {
              sellOptions.push({ ...item, isChecked: true });
            } else {
              sellOptions.push({ ...item, isChecked: false });
            }
          }
        });
        this.constraintOptions = sellOptions;
      },
      (error: any) => {
        console.log('Error getting seller Constraint', error);
      },
      () => console.log('Done getting seller Constraint')
    );
  }

  handleGetStatus() {
    this.addNewListingService.handleGetStatus().subscribe(
      (response: any) => {
        this.statusOptions = response;
      },
      (error: any) => {
        console.log('Error getting status', error);
      },
      () => console.log('Done getting status ')
    );
  }

  handleGetTracts() {
    this.addNewListingService.handleGetTracts().subscribe(
      (response: any) => {
        this.tracts = response;
      },
      (error: any) => {
        console.log('Error getting listing Tracts', error);
      },
      () => console.log('Done getting listing Tracts')
    );
  }

  handleGetUserAccounts() {
    this.myListingsService
      .handleGetUserAccounts(this.loginService.user.id)
      .subscribe(
        (response: any) => {
          this.accountsOptions = response;
          if (this.accountsOptions.length == 1) {
            this.createNewListing.account = this.accountsOptions[0].id;
          }
        },
        (error: any) => {
          console.error('Error getting accounts: ', error);
        },
        () => console.log('Done getting accounts.')
      );
  }

  handleGetOffers(offers: any) {
    let activeOffers = offers?.filter(
      (item: any) => item?.status?.stage != 'Cancelled'
    );
    if (activeOffers.length > 0) {
      return false;
    }
    return true;
  }

  updateListing(listing: any, flag = false) {
    this.myListingsService.updateListing(listing).subscribe(
      (response) => {
        this.spinner.hide();
        this.router.navigate(['/my-listing']);
        this.handleGoBack();
        this.myListingsService.handleResetSetNewList();
        flag
          ? this.toastr.success(environment.LIST_CANCEL_SUCCESS_MESSAGE)
          : this.toastr.success(environment.LIST_UPDATE_SUCCESS_MESSAGE);
      },
      (error: any) => {
        this.spinner.hide();
        Object.keys(error.error).map((key) => {
          this.toastr.error(error.error[key][0], key, {
            progressBar: false,
            tapToDismiss: false,
            disableTimeOut: true,
          });
        });
      },
      () => console.log('Done List update')
    );
  }

  auctionTypeComparator(a: any, b: any) {
    return a && b ? a.id === b.id : false;
  }

  handleOfferDealMessages() {
    this.myOffersService.handleOfferDealMessages().subscribe(
      (response: any) => {
        this.offerConfirmMessages = response;
        if (this.createNewListing.listingStart && this.offerConfirmMessages) {
          this.handleTimeDuration();
        }
      },
      (error: any) => {
        console.error('Error getting key vlaue  : ', error);
      },
      () => console.log('Done getting key vlaue .')
    );
  }

  handleTimeDuration() {
    const { auctionEnd, listingStart } = this.createNewListing;
    const maxDurationItem = this.offerConfirmMessages?.find(
      (item: any) => item.key == environment.MAXIMUM_AUCTION_DURATION
    );
    const maxDuration = maxDurationItem
      ? maxDurationItem.value1 && parseInt(maxDurationItem.value1)
      : null;
    const minDurationItem = this.offerConfirmMessages?.find(
      (item: any) => item.key == environment.MINIMUM_AUCTION_DURATION
    );
    const minDuration = minDurationItem
      ? minDurationItem.value1 && parseInt(minDurationItem.value1)
      : null;
  
    const durationInHours: number = Math.floor(
      (new Date(auctionEnd).getTime() - new Date(listingStart).getTime()) /
        (1000 * 60 * 60)
    );
  
    const maxDurationMilliseconds = maxDuration ? maxDuration * 60 * 60 * 1000 : 0;
    const minDurationMilliseconds = minDuration ? minDuration * 60 * 60 * 1000 : 0;
    const maxAuctionEnd = new Date(new Date(listingStart).getTime() + maxDurationMilliseconds);
    const minAuctionEnd = new Date(new Date(listingStart).getTime() + minDurationMilliseconds);
  
    this.minAuctionDuration = this.customDatePipe.transform(
      minAuctionEnd,
      'yyyy-MM-ddTHH:mm'
    );
    this.maxAuctionDuration = this.customDatePipe.transform(
      maxAuctionEnd,
      'yyyy-MM-ddTHH:mm'
    );
  
    if (
      maxDuration !== null &&
      maxDuration !== undefined &&
      durationInHours > maxDuration
    ) {
      this.toastr.warning(
        `${environment.LISTING_LONGER_DURATION_WARNING} maximum of ${maxDuration} ${environment.LISTING_DURATION_ADJUST_HOURS_MESSAGE}`
      );
      this.createNewListing.auctionEnd = this.customDatePipe.transform(
        maxAuctionEnd,
        'yyyy-MM-ddTHH:mm'
      );
    } else if (
      minDuration !== null &&
      minDuration !== undefined &&
      durationInHours < minDuration
    ) {
      this.toastr.warning(
        `${environment.LISTING_SHORTER_DURATION_WARNING} minimum of ${minDuration} ${environment.LISTING_DURATION_ADJUST_HOURS_MESSAGE}`
      );
      this.createNewListing.auctionEnd = this.customDatePipe.transform(
        minAuctionEnd,
        'yyyy-MM-ddTHH:mm'
      );
    }
  }

 
  
}
