<div class="d-flex">
    <div class="mb-2 col">
        <div class="w-25">
            <h5>PSA </h5>
        </div>
        <div class="">
            <div
                class="mb-3 card d-flex  shadow-sm p-3 mb-2 bg-white pe-auto rounded justify-content-between flex-row p-2 align-items-center ">
                <div>
                    <img src="assets/tag.svg" width='24' height="24" class="m-1" alt="chevron-right" />
                    <a class="marketplace-items " [routerLink]="['/eckard-pending-transactions']">Transactions with
                        Pending PSA
                    </a>({{pendingPSA}})
                </div>
                <div>
                    <img src="assets/chevron-right.svg" width='20' height="24" class="m-1" alt="chevron-right" />
                </div>
            </div>

        </div>
    </div>
    <div class="mb-2 col">
        <div>
            <h5>Asset Transfer</h5>
        </div>
        <div class="">
            <div
                class="mb-3 card d-flex  shadow-sm p-3 mb-2 bg-white pe-auto rounded justify-content-between flex-row p-2 align-items-center ">
                <div>
                    <img src="assets/tag.svg" width='24' height="24" class="m-1" alt="chevron-right" />
                    <a class="marketplace-items " [routerLink]="['/eckard-completed-transactions']">Transactions where
                        Funds Transfer Completed
                    </a>({{completedFT}})
                </div>
                <div>
                    <img src="assets/chevron-right.svg" width='20' height="24" class="m-1" alt="chevron-right" />
                </div>
            </div>

            <div
                class="mb-3 card d-flex  shadow-sm p-3 pe-auto mb-2 bg-white rounded justify-content-between flex-row p-2 align-items-center ">
                <div>
                    <img src="assets/file-minus.svg" width='24' height="24" class="m-1" alt="chevron-right" />
                    <a class="marketplace-items" [routerLink]="['/eckard-pending-asset']">Transactions with Pending
                        Asset Transfer
                    </a>({{pendingPAT}})
                </div>
                <div>
                    <img src="assets/chevron-right.svg" width='20' height="24" class="m-1" alt="chevron-right" />
                </div>
            </div>

        </div>
    </div>
</div>
<div class="d-flex">
    <div class="mb-2 col-6">
        <div>
            <h5>Fund Transfer Methods </h5>
        </div>
        <div
            class="mb-3 card d-flex  shadow-sm p-3 mb-2 bg-white pe-auto rounded justify-content-between flex-row p-2 align-items-center ">
            <div>
                <img src="assets/tag.svg" width='24' height="24" class="m-1" alt="chevron-right" />
                <a class="marketplace-items " [routerLink]="['/eckard-transactions-methods']">New Fund Transfer Methods
                </a>({{totalFTM}})
            </div>
            <div>
                <img src="assets/chevron-right.svg" width='20' height="24" class="m-1" alt="chevron-right" />
            </div>
        </div>

    </div>

</div>