import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CashFlowCalculatorComponent } from './cash-flow-calculator.component';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        CashFlowCalculatorComponent
    ],
    imports: [
        RouterModule,
        FormsModule,
        CommonModule,
        NgxSpinnerModule,

    ],
    providers: [],
    exports: [CashFlowCalculatorComponent]
})
export class CashFlowCalculatorModule {

}
