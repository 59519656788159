import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { CustomDatePipe } from 'src/common/custom-date.pipe';
import {  RouterModule } from '@angular/router';



@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    RouterModule

  ],
  providers: [CustomDatePipe],
  exports: [ResetPasswordComponent]
})
export class ResetPasswordModule { }
