import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/components/auth/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./../components/auth/login/login.module').then(m => m.loginModule) },
  { path: 'sign-up', loadChildren: () => import('./../components/auth/sign-up/sign-up.module').then(m => m.SignUpModule) },
  { path: 'reset-password',  loadChildren: () => import('./../components/auth/sign-up/sign-up.module').then(m => m.SignUpModule) },
  { path: 'market-place', loadChildren: () => import('./../components/market-place/market-place.module').then(m => m.MarketPlaceModule), canActivate: [AuthGuard] },
  { path: 'my-offers', loadChildren: () => import('./../components/pages/buyer/my-bids/my-bids.module').then(m => m.MyBidsModule), canActivate: [AuthGuard] },
  { path: 'all-listing', loadChildren: () => import('./../components/pages/buyer/my-bids/all-active-listing/all-active-listings.module').then(m => m.AllActiveListingsModule), canActivate: [AuthGuard] },
  { path: 'direct-sale/:id', loadChildren: () => import('./../components/pages/buyer/my-bids/all-active-listing/all-active-listings.module').then(m => m.AllActiveListingsModule), canActivate: [AuthGuard] },
  { path: 'buyer-pending-transactions', loadChildren: () => import('src/components/pages/buyer/buyer-transactions/buyer.transactions.module').then(m => m.BuyerTransactionsModule), canActivate: [AuthGuard] },
  { path: 'buyer-closed-transactions', loadChildren: () => import('src/components/pages/buyer/buyer-transactions/buyer.transactions.module').then(m => m.BuyerTransactionsModule), canActivate: [AuthGuard] },
  { path: 'buyer-notifications', loadChildren: () => import('./../components/pages/buyer/buyer-notifications/buyer-notifications.module').then(m => m.BuyerNotificationsModule), canActivate: [AuthGuard] },
  { path: 'my-listing', loadChildren: () => import('./../components/pages/seller/my-listing/my-listing.module').then(m => m.MyListingModule), canActivate: [AuthGuard] },
  { path: 'seller-pending-transactions', loadChildren: () => import('./../components/pages/seller/seller-transactions/seller-transactions.module').then(m => m.SellerTransactionsModule), canActivate: [AuthGuard] },
  { path: 'seller-closed-transactions', loadChildren: () => import('./../components/pages/seller/seller-transactions/seller-transactions.module').then(m => m.SellerTransactionsModule), canActivate: [AuthGuard] },
  { path: 'seller-notifications', loadChildren: () => import('./../components/pages/seller/seller-notifications/seller-notifications.module').then(m => m.SellerNotificationsModule), canActivate: [AuthGuard] },
  { path: 'sec-disclosure', loadChildren: () => import('./../components/pages/informations/sec-disclosure/sec-disclosure.module').then(m => m.SECDisclosureModule), canActivate: [AuthGuard] },
  { path: 'user-agreement', loadChildren: () => import('./../components/pages/informations/user-agreement/user-agreement.module').then(m => m.UserAgreementModule), canActivate: [AuthGuard] },
  { path: 'profile', loadChildren: () => import('src/components/pages/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard] },
  { path: 'eckard-pending-transactions', loadChildren: () => import('./../components/pages/eckard-staff-place/eckard-transactions/eckard-transactions.module').then(m => m.EckardTransactionsModule), canActivate: [AuthGuard] },
  { path: 'eckard-completed-transactions', loadChildren: () => import('./../components/pages/eckard-staff-place/eckard-transactions/eckard-transactions.module').then(m => m.EckardTransactionsModule), canActivate: [AuthGuard] },
  { path: 'eckard-pending-asset', loadChildren: () => import('./../components/pages/eckard-staff-place/eckard-transactions/eckard-transactions.module').then(m => m.EckardTransactionsModule), canActivate: [AuthGuard] },
  { path: 'eckard-transactions-methods', loadChildren: () => import('./../components/pages/eckard-staff-place/eckard-transactions-methods/eckard-transactions-methods.module').then(m => m.EckardTransactionsMethodsModule), canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'market-place', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
