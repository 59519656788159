import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constraint, MyListings, Status } from 'src/components/model/my-listings';
import { MyOffers, Offer } from 'src/components/model/my-offer';
import { LoginService } from 'src/components/services/login.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { AddNewListingService } from '../../../services/add-new-listing.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-my-bids',
  templateUrl: './my-bids.component.html',
  styleUrls: ['./my-bids.component.scss'],
})
export class MyBidsComponent implements OnInit {
  filterByProject: string = '';
  type = 'Silent- Minimum Ask';
  statusOptions!: Status[];

  page: number = 1;
  count: number = 0;
  tableSize: number = 50;
  tableSizes: number[] = [3, 6, 9, 12];
  testMyOffer = [];
  myOffers!: MyOffers[];
  constraintOptions: Constraint[] = [];
  listDetails!: MyListings;

  newOffer: Offer = {
    id: null,
    contact: null,
    status: null,
    constraints: [],
    comments: '',
    offerAmount: 0,
  };
  offersColumns: Array<String> = [
    'Seller / Project',
    'Auction End',
    'Listed NMA',
    'Minimum Ask',
    'Buy Now at',
    '# Offers',
    'Highest Offer',
    'My Offer',
  ];
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private myListingsService: MyListingsService,
    private myOffersService: MyOffersService,
    private addNewListingService: AddNewListingService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (
      this.loginService?.user?.status != 'active' ||
      this.loginService?.user?.role?.name == 'Eckard'
    ) {
      this.router.navigate(['/market-place']);
      return;
    }
    this.handleConstraint();
    this.getAllMyOffers();
    this.handleGetStatus();
  }

  getAllMyOffers() {
    let offset = (this.page - 1) * this.tableSize;

    this.spinner.show();
    this.myOffersService
      .getAllMyOffers(this.loginService.user.id, this.tableSize, offset)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.myOffers = response?.data
          this.page = response?.metadata?.page
          this.count = response?.metadata?.total_results
          this.tableSize = response?.metadata?.limit
        },
        (error: any) => {
          this.spinner.hide();

          console.log(error);
        },
        () => console.log('Done getting all my active offers')
      );
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getAllMyOffers();
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  handleLength(array: any) {
    if (array && array.length > 0) {
      return true;
    }
    return false;
  }

  handleConstraint() {
    this.addNewListingService.handleConstraint().subscribe(
      (response) => {
        const buyOptions: any = [];
        response?.map((item) => {
          if (item.buyLabel) {
            buyOptions.push({ ...item, isChecked: false });
          }
        });
        this.constraintOptions = buyOptions;
      },
      (error: any) => {
        console.log('Error getting buyer Constraint', error);
      },
      () => console.log('Done getting buyer Constraint')
    );
  }

  handleListDetails(id: number, offerId: any) {
    this.spinner.show();

    this.myListingsService.getMyList(id).subscribe(
      (response: MyListings) => {
        this.spinner.hide();
        this.listDetails = response;
        if (!offerId) {
          this.newOffer.offerAmount = parseInt(response.minimumAsk);
          this.newOffer.id = null;
          this.newOffer.contact = null;
          this.newOffer.status = null;
          this.newOffer.constraints = [];
          this.newOffer.comments = '';
        }
      },
      (error: any) => {
        this.spinner.hide();

        console.log('Error getting list details', error);
      },
      () => console.log('Done getting list details')
    );

    if (offerId) {
      this.spinner.show();

      this.myOffersService.getofferDetails(offerId).subscribe(
        (response: any) => {
          this.spinner.hide();

          this.newOffer = response;
          this.constraintOptions = this.constraintOptions?.map((obj: any) => {
            return {
              id: obj.id,
              constraint: obj.constraint,
              buyLabel: obj.buyLabel,
              info: obj.info,
              violation_message: obj.violation_message,
              isChecked: this.newOffer.constraints?.some(
                (item: any) => item.id === obj.id
              ),
            };
          });
        },
        (error: any) => {
          this.spinner.hide();

          console.log('Error getting offer details', error);
        },
        () => console.log('Done getting offer details')
      );
    }
  }

  handleUpdateOffers() {
    this.getAllMyOffers();
  }

  handleGetStatus() {
    this.addNewListingService.handleGetStatus().subscribe(
      (response) => {
        this.statusOptions = response;
      },
      (error: any) => {
        console.log('Error getting  status', error);
      },
      () => console.log('Done getting  status ')
    );
  }

  onSortChange(event: any): void {
    let sortProperty = '';
    switch (event.active) {
      case 'Seller / Project':
        sortProperty = 'accountMpName';
        break;
      case 'Auction End':
        sortProperty = 'auctionEnd';
        break;
      case 'Listed NMA':
        sortProperty = 'listedNMA';
        break;
      case 'Minimum Ask':
        sortProperty = 'minimumAsk';
        break;
      case 'Buy Now at':
        sortProperty = 'buyNowPrice';
        break;
      case '# Offers':
        sortProperty = 'noOfBids';
        break;
      case 'Highest Offer':
        sortProperty = 'highestBid';
        break;
      case 'My Offer':
        sortProperty = 'offerAmount';
        break;
      default:
        return;
    }
    const sortOrder = event.direction === 'asc' ? 1 : -1;

    this.myOffers = this.myOffers.sort((a: any, b: any) => {
      const aValue = a[sortProperty];
      const bValue = b[sortProperty];
      if (aValue < bValue) {
        return -1 * sortOrder;
      } else if (aValue > bValue) {
        return 1 * sortOrder;
      } else {
        return 0;
      }
    });
  }
}
