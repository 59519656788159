<div class="mt-4">
    <label for="exampleFormControlInput1" class="form-label">Constraints / Contingencies</label>
    <div class="col-sm-10">

        <div class="form-check pe-auto" *ngFor="let item of constraintOptions">
            <input class="form-check-input" type="checkbox" [(ngModel)]="item.isChecked" (change)="changeSelection()"
                [name]="item.constraint" [id]="'constraint'+item.id" [disabled]="!isListDraft">
            <label [for]="'constraint'+item.id">
                {{item.sellLabel}}
            </label>
            <span class="tooltip-container">
                <span class="tooltip-container-icon">
                    <span class="tooltip-container-text">
                        {{item.info}}
                    </span>
                    <span class="tooltip-container-img">
                        <img src="assets/cash-flow.svg" width='16' height="16" class="ml-1" alt="chevron-info" />
                    </span>
                </span>
            </span>
        </div>
    </div>
</div>

<div class="mt-3">
    <label for="comments" class="form-label">Additional Comments</label>
    <textarea class="form-control" [(ngModel)]="createNewListing.comments" id="comments" name="comments" rows="5"
        [disabled]="!isListDraft"></textarea>
</div>