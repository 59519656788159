<div class="container card mt-4 pt-4 h-100">

    <app-breadcrumb-container
    [containerClass]="'breadcrumb-container'"
    [breadcrumbItems]="[
      { label: 'Home', route: '/home' },
      { label: 'MarketPlace', route: '/market-place' },
      { label: 'Notifications', route: '' }
    ]"
  ></app-breadcrumb-container>

    <div class="d-flex gap-5 mt-4 mb-3 align-items-center">
        <div>
            <input type="search" id="searchParam" name="searchParam" placeholder="Serach" class="form-control" [(ngModel)]="searchParam"
                (ngModelChange)="handleChange()" />
        </div>

    </div>

    <div class="table-responsive">

        <table class="table" matSort (matSortChange)="onSortChange($event)">
            <thead>
                <tr>
                    <th *ngFor="let item of notificationsColumns ; index as i " class="text-secondary "
                        mat-sort-header="{{item}}">{{item}}</th>
                </tr>
            </thead>
            <tbody class="" id="accordion">
                <ng-container *ngFor=" let notification of notificationsData | paginate
            : {
                itemsPerPage: tableSize,
                currentPage: page,
                totalItems: count
              };
        index as i">

                    <tr class="text-secondary">

                        <td id="heading{{i}}">
                            <span data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="false"
                                [attr.aria-controls]="'collapse'+i" class=" data-details collapsed pe-auto"
                                title="Notification Details">
                                {{notification?.subject}}
                            </span>
                        </td>

                        <td>
                            {{notification.created_at | date:'d MMM, y, h:mm a'}}
                        </td>
                    </tr>
                    <tr id="collapse{{i}}" class=" collapse fade" [attr.aria-labelledby]="'heading'+i"
                        data-parent="#accordion">
                        <td colspan="2">

                            <div class="card-body border shadow-lg p-4 mb-5 bg-white rounded">

                                <div class="mt-4">
                                    <h5 class="offer-heading">Notifications Details</h5>
                                </div>
                                <div class="d-flex flex-row mt-4 gap-5">
                                    <div class="col">

                                        <label class="form-label fw-bold" [innerHTML]="notification?.subject ?? '' "></label>
                                    </div>
                                    <div class="col">

                                        <div
                                            *ngIf="notification.notification_template.buyer || notification.notification_template.seller; else elseNotificationsSent">
                                            The notification is sent to
                                            <ng-container
                                                *ngIf="notification.notification_template.buyer && !notification.notification_template.seller">
                                                Buyer
                                            </ng-container>
                                            <ng-container
                                                *ngIf="!notification.notification_template.buyer && notification.notification_template.seller">
                                                Seller
                                            </ng-container>
                                            <ng-container
                                                *ngIf="notification.notification_template.buyer && notification.notification_template.seller">
                                                Seller and Buyer
                                            </ng-container>
                                        </div>
                                        <ng-template #elseNotificationsSent>
                                            <ng-container
                                                *ngIf="notification.notification_template.buyer == null && notification.notification_template.seller == null; else isCapitalAmountBlock">
                                            </ng-container>
                                            <ng-template #isCapitalAmountBlock>
                                                The notification has not been sent to Seller and Buyer
                                            </ng-template>
                                        </ng-template>

                                    </div>


                                </div>
                                <div class="d-flex flex-row mt-3 gap-5">
                                    <div class="col" [innerHTML]="sanitizeHTML(notification?.body ?? '')">

                                    </div>

                                </div>

                                <div class="mt-4">

                                    <button type="submit" class="btn btn-outline-dark cancel-button  mb-2 fw-bold"
                                        data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="false"
                                        [attr.aria-controls]="'collapse'+i">
                                        Close Panel
                                    </button>
                                </div>

                            </div>


                        </td>
                    </tr>

                </ng-container>
                <tr  *ngIf="!handleLength(notificationsData)">
                    <td colspan="2" class="text-center text-secondary">No Record Found</td>  
                </tr >


            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)"
            *ngIf="handleLength(notificationsData)">
        </pagination-controls>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
</ngx-spinner>