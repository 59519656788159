import {
  Account,
  AccountsProjects,
  CashConfig,
  CashFlow,
  IncomListing,
  MyAllListing,
} from './../model/my-listings';
import {  HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ContactAccount,
  MyListing,
  MyListings,
  ListingCost
} from '../model/my-listings';
import { tap, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { CustomDatePipe } from 'src/common/custom-date.pipe';

@Injectable({
  providedIn: 'root',
})
export class MyListingsService {
  newListing: MyListing = {
    listing_type: 1,
    status: null,
    listingName: '',
    listingStart: '',
    auction_type: null,
    auctionEnd: '',
    comments: '',
    account: null,
    project: null,
    nma: null,
    minimumAsk: null,
    buyNowPrice: null,
    constraints: [],
    offer: [],
    directSaleToken: '',
    is_cancel_allowed: false,
  };
  isListEdit: boolean = false;
  showOffers: boolean = false;
  isListDraft: boolean = true;
  userAccountsAndProjects!: AccountsProjects[];

  constructor(
    private apiService: ApiService,
    private customDatePipe : CustomDatePipe
  ) {
    this.handleSetDate();
  }

  handleResetSetNewList() {
    this.newListing = {
      listing_type: 1,
      status: null,
      listingName: '',
      listingStart: '',
      auction_type: null,
      auctionEnd: '',
      comments: '',
      account: null,
      project: null,
      nma: null,
      minimumAsk: null,
      buyNowPrice: null,
      constraints: [],
      offer: [],
      directSaleToken: '',
    };
    this.isListEdit = false;
    this.isListDraft = true;
    this.showOffers = false;
    this.handleSetDate();
  }

  handleSetDate() {
    
  const todayMidnight = new Date();
  todayMidnight.setHours(0, 0, 0, 0);
  const today = this.customDatePipe.transform(todayMidnight, 'yyyy-MM-ddTHH:mm');
  this.newListing.listingStart = today;
  }

  getAllMyListings(
    id: number,
    limit = 1,
    offset = 0,
    status = 'Active'
  ): Observable<MyAllListing> {
    const params = new HttpParams()
      .set('limit', limit)
      .set('offset', offset)
      .set('status', status);

    const res = this.apiService
      .request(
        `${environment.API_BASE_URL}/listing/contact/${id}`,
        'GET',
        null,
        params
      )
      .pipe(tap((response: any) => response.body));

    return res;
  }

  getMyList(id: number): Observable<MyListings> {
    const res = this.apiService
      .request(`${environment.API_BASE_URL}/listing/${id}`, 'GET')
      .pipe(
        map((list: any) => {
          return {
            ...list,
            auctionEnd: this.customDatePipe.getFormattedDate(list.auctionEnd),
            listingStart: this.customDatePipe.getFormattedDate(list.listingStart),
          };
        })
      );
    return res;
  }

  createNewListing(newList: MyListing): Observable<MyListing> {
    const startLocalTime = new Date(newList.listingStart);
    const endLocalTime = new Date(newList.auctionEnd);
    newList.auctionEnd = this.customDatePipe.transform(endLocalTime, "yyyy-MM-ddTHH:mm:ss\'Z\'", 'UTC');
    newList.listingStart = this.customDatePipe.transform(startLocalTime, "yyyy-MM-ddTHH:mm:ss\'Z\'", 'UTC');
    newList.auction_type = newList?.auction_type?.id;
    return this.apiService
      .request(`${environment.API_BASE_URL}/listing/`, 'POST', newList)
      .pipe(tap((response: any) => response.body));
  }

  updateListing(updateList: MyListing): Observable<MyListing> {
    const startLocalTime1 = new Date(updateList.listingStart);
    const endLocalTime1 = new Date(updateList.auctionEnd);
    updateList.auctionEnd = this.customDatePipe.transform(endLocalTime1,"yyyy-MM-ddTHH:mm:ss\'Z\'", 'UTC');
    updateList.listingStart = this.customDatePipe.transform(startLocalTime1,"yyyy-MM-ddTHH:mm:ss\'Z\'", 'UTC');
    updateList.auction_type = updateList?.auction_type?.id;
    return this.apiService
      .request(
        `${environment.API_BASE_URL}/listing/${updateList.id}/`,
        'PATCH',
        updateList      )
      .pipe(tap((res: any) => res.body));
  }

  handleGetUserAccounts(id: number): Observable<Account[]> {
  
    const res = this.apiService
      .request(
        `${environment.API_BASE_URL}/account/getbycontact/${id}/`,
        'GET'      )
      .pipe(tap((res: any) => res.body));
    return res;
  }

  handleGetProjects(id: number): Observable<ContactAccount[]> {
    const res = this.apiService
      .request(
        `${environment.API_BASE_URL}/investment/getbyaccount/${id}/`,
        'GET'
      )
      .pipe(tap((response: any) => response.body));
    return res;
  }

  handleGetListingCost(
    accountId: number,
    projectId: number
  ): Observable<ListingCost> {
    const res = this.apiService
      .request(
        `${environment.API_BASE_URL}/investment/account/${accountId}/project/${projectId}`,
        'GET'
      )
      .pipe(tap((response: any) => response.body));
    return res;
  }

  handleGetIncomeListing(
    accountId: number,
    projectId: number
  ): Observable<IncomListing> {
    const res = this.apiService
      .request(
        `${environment.API_BASE_URL}/income/account/${accountId}/project/${projectId}`,
        'GET'
      )
      .pipe(tap((response: any) => response.body));
    return res;
  }

  handleGetCashFlow(projectId: number): Observable<any> {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/project/${projectId}/recent_prices`,
      'GET'
    );
    return res;
  }

  handleGetCashConfig(contactId: number): Observable<any> {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/cash_config/contact/${contactId}`,
      'GET'
    );
    return res;
  }

  handleCreateCashConfig(body: any): Observable<any> {
   
    body = {
      contact: body.contact,
      json_fields: {
        months: body.months,
        decline: body.decline,
        oil: body.oil,
        gas: body.gas,
      },
    };
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/cash_config/`,
      'POST',
      body    );
    return res;
  }

  handleUpdateCashConfig(body: any): Observable<any> {
    body = {
      contact: body.contact,
      id: body.id,
      json_fields: {
        months: body.months,
        decline: body.decline,
        oil: body.oil,
        gas: body.gas,
      },
    };
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/cash_config/${body.id}`,
      'PATCH',
      body
    );
    return res;
  }

  handlePendingListsTrancastions(id: any): Observable<any> {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/listing/contact/${id}/pending_list`,
      'GET'
    );
    return res;
  }

  handleCalculateCashFlow(
    user: any,
    basicCashFlow: CashConfig,
    cashFlow: CashFlow,
    listDetails: any
  ) {
    const body = {
      contact: user.id,
      project: listDetails?.project?.id,
      json_fields: {
        months: basicCashFlow.months,
        decline: basicCashFlow.decline,
        oil: basicCashFlow.oil,
        gas: basicCashFlow.gas,
      },
    };
    this.handleCreateHistory(body).subscribe(
      (response) => {
        console.log('Re-calculate history save', response);
      },
      (error: any) => {
        console.error('Error create history  : ', error);
      }
    );
    let gasArray = 0;
    let oilArray = 0;
    let currentGas = cashFlow.gas;
    let currentOil = cashFlow.oil;

    for (let i = 1; i <= basicCashFlow.months; i++) {
      let gasDecline = currentGas * (basicCashFlow.decline / 100);
      let oilDecline = currentOil * (basicCashFlow.decline / 100);
      currentGas = currentGas - gasDecline;
      currentOil = currentOil - oilDecline;
      gasArray += currentGas;
      oilArray += currentOil;
    }

    gasArray *= basicCashFlow.gas;
    oilArray *= basicCashFlow.oil;
    gasArray /= cashFlow.totalProjectNma;
    oilArray /= cashFlow.totalProjectNma;
    return (gasArray + oilArray) * listDetails.nma
      ? (gasArray + oilArray) * listDetails.nma
      : 0;
  }

  handleCreateHistory(body: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/history/`,
      'POST',
      body    );
    return res;
  }

  handleGetSellerPendingTransactions(
    id: any,
    limit = 1,
    offset = 0,
    status: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('limit', limit)
      .set('offset', offset)
      .set('status', this.handleTransactionStatusFilter(status));
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/transaction/seller/${id}`,
      'GET',
      null,
      params
    );
    return res;
  }

  handleGetBuyerPendingTransactions(
    id: any,
    limit = 1,
    offset = 0,
    status: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('limit', limit)
      .set('offset', offset)
      .set('status', this.handleTransactionStatusFilter(status));
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/transaction/buyer/${id}`,
      'GET',
      null,
      params
    );
    return res;
  }

  handleGetEckardTransactions(status: string) {
    const params = new HttpParams().set('statusName', status);
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/transaction/status`,
      'GET',
      null,
      params
    );
    return res;
  }

  handleUpdateEckardTransactions(transaction: any) {
    const body = {
      status: transaction.status.id,
    };
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/transaction/${transaction.id}/`,
      'PATCH',
      body    );
    return res;
  }
  handleGetTransactions(transaction: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/transaction/${transaction.id}/`,
      'GET'
    );
    return res;
  }

  handleDeleteFTM(id: any, flag: any) {
    const params = new HttpParams().set('forceDelete', flag);
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/fund_transfer_method/${id}/`,
      'DELETE',
      null,
      params
    );
    return res;
  }

  handleGetEckardTransactionsMethods() {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/fund_transfer_method/old_records/`,
      'GET'
    );
    return res;
  }

  handleNotifications(
    flag: any,
    id: any,
    limit = 1,
    offset = 0,
    query = ''
  ): Observable<any> {
    const params = new HttpParams()
      .set('limit', limit)
      .set('offset', offset)
      .set('query', query);

    let type = flag == 'Sell' ? 'seller' : 'buyer';
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/notifications/contact/${type}/${id}`,
      'GET',
      null,
      params
    );
    return res;
  }

  handleTransactionsNotifications(body: any) {
    body.subject = body.trxnId + ' ' + body.subject;
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/notifications/`,
      'POST',
      body
    );
    return res;
  }

  handleTransactionStatusFilter(status: string) {
    let transaction_Status = '';
    if (
      status == 'sellerPendingTransactions' ||
      status == 'buyerPendingTransactions'
    ) {
      transaction_Status = 'pending';
    } else if (
      status == 'buyerClosedTransactions' ||
      status == 'sellerClosedTransactions'
    ) {
      transaction_Status = 'closed';
    }
    return transaction_Status;
  }
}
