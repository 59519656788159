import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OfferConfirmMessages } from 'src/components/model/login';
import { CapitalAmmount } from 'src/components/model/my-listings';
import { CommonService } from 'src/components/services/common.service';
import { LoginService } from 'src/components/services/login.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-capital-amount',
  templateUrl: './capital-amount.component.html',
  styleUrls: ['./capital-amount.component.scss']
})
export class CapitalAmountComponent implements OnInit {
  @Input() capitalAmount!: CapitalAmmount
  isShow: boolean = false
  offerConfirmMessages!: OfferConfirmMessages[]
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: ''
  };

  constructor(private loginService: LoginService, private spinner: NgxSpinnerService, private myOffersService: MyOffersService,
    private toastr: ToastrService,private commonService: CommonService) { }

  ngOnInit(): void {
    this.handleOfferDealMessages()
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  handleAddCapitalAmount() {
    this.spinner.show()
    let body = {
      id: this.capitalAmount.id,
      availableCapital: this.capitalAmount.availableCapital + this.capitalAmount.addFunds,
      signup_journey: false
    }
    let notification = {
      recipient_id: this.loginService?.user?.id,
      availableCapital: this.capitalAmount.availableCapital + this.capitalAmount.addFunds,
      addFund: this.capitalAmount.addFunds,
    }
    this.loginService.updateProfileDetails(body).subscribe(
      (response) => {
        this.capitalAmount.availableCapital = response.availableCapital
        this.isShow = false
        this.spinner.hide()
        this.toastr.success(environment.FUNDS_ADD_SUCCESS_MESSAGE);
        this.handleCapitalAmount(notification)
      },
      (error: any) => {
        this.isShow = false
        this.spinner.hide()
        this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });
        console.log("error", error)
      },
      () => console.log("Done add funds ammount"));
  }

  handleOfferDealMessages() {
    this.spinner.show()
    this.myOffersService.handleOfferDealMessages().subscribe(
      (response: any) => {
        this.spinner.hide()
        this.offerConfirmMessages = response
      },
      (error: any) => {
        this.spinner.hide()
        console.error("Error getting Offer Disclaimer: ", error);
      },
      () => console.log("Done getting Offer Disclaimer.")
    )
  }

  handleMessage() {
    this.isShow = true
    let message = this.offerConfirmMessages?.filter((item: any) => item.key == environment.ADD_FUNDS_DISCLAIMER)
    this.offerDisclaimer = message[0]

  }

  handleCapitalAmount(notification: any){
    this.loginService.handleCapitalAmount(notification).subscribe(
      (response) => {
        this.spinner.hide()
      },
      (error: any) => {
        this.spinner.hide()
        this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });
        console.log("error", error)
      },
      () => console.log("Capital Amount Notification has sent"));
  }

}
