import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { MyOffersService } from 'src/components/services/my-offers.service'
import { MatSort } from '@angular/material/sort';
import { OfferConfirmMessages } from 'src/components/model/login'
import { SafeHtml } from '@angular/platform-browser'
import { CommonService } from 'src/components/services/common.service'
import { Status } from 'src/components/model/my-listings'
import { Offer } from 'src/components/model/my-offer'
import { environment } from 'src/environments/environment';


@Component({
  selector: 'all-offers-tab',
  templateUrl: './all-offers-tab.component.html',
  styleUrls: ['./all-offers-tab.component.css']
})
export class AllOffersTabComponent implements OnInit {
  @Input() ListingDetails!: any
  @Input() statusOptions!: Status[]

  @Output() handleSubmit = new EventEmitter()

  page: number = 1
  count: number = 0
  tableSize: number = 50
  tableSizes: number[] = [3, 6, 9, 12]
  offers!: Offer[]
  offerConfirmMessages!: OfferConfirmMessages[]
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: ''
  };
  selectedOffer!: Offer

  offersTableHead = [
    'Buyer',
    'Offer Amount',
    'Action',
    'Constraints',
    'Comments'
  ]
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private myOffersService: MyOffersService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private commonService: CommonService  ) { }

  ngOnInit(): void {
    this.handleOfferDealMessages()
    this.handleGetAllOffers()
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }
  
  handleGetAllOffers() {
    this.offers = this.ListingDetails?.offer?.filter((item: any) => {
      if (item.status.status != 'Cancelled') {
        this.ListingDetails?.listConstraints?.map((list: any) => {
          if (list.constraintType == 'block') {
            item.constraints.map((offerContraint: any) => {
              if (offerContraint.id == list.id) {
                offerContraint.block = true;
              }
            })
          }
          if (list.constraintType == 'required') {
            const offerContraint = item.constraints.find(
              (offerContraint: any) => offerContraint.id == list.id
            )
            if (!offerContraint) {
              item.constraints.push({ ...list, missing: true })
              // missing.push()
            }
          }
        })
        // item.constraints.push([...missing])

        return item;
      }
    })
    this.offers?.sort((a: any, b: any) => b.offerAmount - a.offerAmount);
  }

  onTableDataChange(event: any) {
    this.page = event
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value
    this.page = 1
  }

  handleOfferDealMessages() {
    this.spinner.show()

    this.myOffersService.handleOfferDealMessages().subscribe(
      (response: any) => {
        this.spinner.hide()
        this.offerConfirmMessages = response
      },
      (error: any) => {
        this.spinner.hide()
        console.error('Error getting Offer : ', error)
      },
      () => console.log('Done getting Offer .')
    )
  }

  handleSellMessage(offer: Offer) {
    this.selectedOffer = offer
    let message = this.offerConfirmMessages?.filter(
      (item: any) => item.key == environment.SELLING_DISCLAIMER
    )
    this.offerDisclaimer = message[0]
  }

  handleSubmitOffer() {
    this.spinner.show()
    let activeItem = this.statusOptions?.find(
      (item: any) => item.status == 'Accepted'
    )
    this.myOffersService
      .handleCheckListStatus(this.ListingDetails.id)
      .subscribe(
        (response: any) => {
          if (response.length == 0) {
            let request = {
              offer: {
                offerAmount: this.selectedOffer.offerAmount,
                status: activeItem?.id,
                constraints: this.selectedOffer.constraints?.map(
                  (item: any) => item.id
                ),
                comments: this.selectedOffer.comments,
                contact: this.selectedOffer?.contact?.id
              },
              listing_id: this.ListingDetails.id,
              acceptedOffer: true
            }
            this.handleUpdateOffer(this.selectedOffer.id, request)
          } else {
            this.spinner.hide()
            this.handleSubmit.emit()
            this.router.navigate(['/my-listing'])
            this.toastr.success(environment.OFFER_ALREADY_ACCEPTED)
          }
        },
        (error: any) => {
          this.spinner.hide()
          console.log('Error getting get handleCheckListStatus', error)
        },
        () => console.log('Done getting get handleCheckListStatus')
      )
  }

  handleUpdateOffer(id: any, body: any) {
    this.myOffersService.handleUpdateOffer(id, body).subscribe(
      response => {
        this.spinner.hide()
        if (response) {
          this.handleSubmit.emit()
          this.router.navigate(['/my-listing'])
          this.toastr.success(environment.OFFER_ACCEPTED_SUCCESS_MESSAGE)
        }
      },
      (error: any) => {
        this.spinner.hide()
        this.toastr.error(environment.OFFER_NOT_FOUND_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        })
        console.error('Error getting Offer : ', error)
      },
      () => console.log('Done getting Offer .')
    )
  }

  onSortChange(event: any): void {
    let sortProperty = '';
    switch (event.active) {
      case 'Buyer':
        sortProperty = 'contact.mpName'
        break;
      case 'Offer Amount':
        sortProperty = 'offerAmount'
        break;
      case 'Constraints':
        sortProperty = 'Account/Project'
        break;
      case 'Comments':
        sortProperty = 'comments'
        break;

      default:
        return
    }
    const sortOrder = event.direction === 'asc' ? 1 : -1;
    this.offers = this.offers?.sort((a: any, b: any) => {
      const aValue = this.getPropertyValue(a, sortProperty);
      const bValue = this.getPropertyValue(b, sortProperty);

      if (aValue < bValue) {
        return -1 * sortOrder;
      } else if (aValue > bValue) {
        return 1 * sortOrder;
      } else {
        return 0;
      }
    });
  }

  getPropertyValue(object: any, propertyPath: string): any {
    const props = propertyPath.split('.');
    let value = object;
    for (const prop of props) {
      value = value[prop];
    }
    return value;
  }

}
