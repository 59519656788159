import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BreadcrumbItem } from '../model/my-listings';


@Component({
  selector: 'app-breadcrumb-container',
  templateUrl: './breadcrumb-container.component.html'
})
export class BreadcrumbComponent implements OnInit {

  @Input() isActiveUser: boolean = false
  @Input() containerClass: string = ''
  @Input() breadcrumbItems!: BreadcrumbItem[]
  @Input() breadcrumbItemsRoute: string = ''
  @Output() handleResetClick = new EventEmitter()


  constructor() {}

  ngOnInit(): void {
    console.log('');
  }

  handleClick() {
    this.handleResetClick.emit()
  }

}
